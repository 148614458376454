// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hw-topbar {
  position: fixed;
  z-index: 1000;
  width: 100%;
}

.menu-button {
  font-size: 30px;
}

.topbar-logo {
  width: 100px !important;
}

@media only screen and (max-width: 550px) {
  .topbar-logo {
    display: none;
  }
  .hide-on-mobile-password-change {
    display: none;
  }
}
.spacer {
  flex: 1 1 auto;
}

/* icons */
.toolbar-icon {
  font-size: 35px;
  color: #ffffff;
  margin-left: 10px;
  margin-top: 0.5em;
}

.icon-blue {
  color: #0088cc;
}

i {
  text-align: center;
  vertical-align: middle;
}

.mat-badge-content {
  font-size: 10px;
}

.hotel-selection-container {
  width: 330px;
  padding-top: 1.4rem;
}
.hotel-selection-container::ng-deep .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding: 0 0.5rem;
}
.hotel-selection-container::ng-deep .mat-mdc-text-field-wrapper {
  border-radius: 0.25rem;
}
.hotel-selection-container::ng-deep .mat-mdc-form-field-infix {
  padding: 0 1rem;
  margin-bottom: 0.5rem;
}

.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: 0px !important;
  margin-right: -1em;
}

.mat-select {
  width: auto !important;
}

.mat-select-value {
  overflow: inherit !important;
}

.mat-select-trigger {
  width: max-content !important;
}

.notification-container {
  margin-top: 0.2rem;
  margin-left: 1rem;
  font-size: 30px;
}

.message-class {
  color: green;
  font-size: 25px;
  text-align: end;
  margin-top: 18px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
