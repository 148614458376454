// Angular
import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
// 3rdparty
import { TranslateService } from '@ngx-translate/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription, Subject, combineLatest, Observable, map, subscribeOn, identity } from 'rxjs';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { saveFile, saveAs } from 'file-saver';
// Hostware
import { SelectItem } from '@app/core/classes/SelectItem';
import { RequestResult } from '@app/core/dto/RequestResult';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { TopBarManagerService } from '@app/core/services/topbar-manager.service';
import { CleaningTypeService } from '@app/kip/services/cleaning-type.service';
import { HokParamsService } from '@app/kip/services/hok-params.service';
import { KipUserService } from '@app/kip/services/kip-user.service';
import { KipUserGroupService } from '@app/kip/services/kip-usergroup.service';
import { RoomTypeService } from '@app/kip/services/roomtype-service';
import { KipUserGroup } from '@app/kip/dto/KipUserGroup';
import { DailyTaskService } from '@app/kip/services/daily-task.service';
import { RoleType } from '@app/shared/enums/roles';
import { KipUser } from '@app/kip/dto/KipUser';
import { vwKipUserRoles } from '@app/kip/viewdto/vwKipUserRoles';
import { CleaningSchedule } from '@app/kip/dto/CleaningSchedule';
import { CleaningType } from '@app/kip/dto/CleaningType';
import { RoomType } from '@app/kip/dto/RoomType';
import { RoleHelperService } from '@app/kip/services/role-helper.service';
import { CrudMessagesService } from '@app/kip/services/crud-messages.service';
import { vwTaskListFilter } from '@app/kip/viewdto/vwTaskListFilter';
import { RouterExtraService } from '@app/kip/services/router-extra.service';
import { SpinnerService } from '@app/core/services/spinner.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { KipModule } from '@app/kip/kip.module';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { RoomState } from '@app/shared/enums/roomstates';
import { RoomStateTransition } from '@app/shared/enums/roomstatetransitions';
import { MatExpansionPanel } from '@angular/material/expansion';
import { AuthService } from '@app/core/services/auth.service';
import { MaintenanceType } from '@app/kip/dto/MaintenanceType';
import { vwMaintenanceSchedule } from '@app/kip/viewdto/vwMaintenanceSchedule';
import { CdkDrag, CdkDragDrop, CdkDragEnd, CdkDragStart, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { vwMaintenanceType } from '@app/kip/viewdto/vwdailytask/vwMaintenanceType';
import { StyleHelperService } from '@app/kip/services/style-helper.service';
import { Room } from '@app/kip/dto/Room';
import { MaintenanceTypeService } from '@app/kip/services/maintenance-type.service';
import { vwCleaningState } from '@app/kip/viewdto/vwCleaningState/vwCleaningState';
import { ICanComponentDeactivate } from '@app/kip/guards/can-deactivate-guard.service';
import { HwConfirmationDialogData } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.component';
import { HwConfirmationDialogService } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.service';
import { TaskItemCardComponent } from '@app/kip/components/task-item-card/task-item-card.component';
import { EventManager } from '@angular/platform-browser';
import { SchedulerService } from '@app/kip/services/scheduler.service';
import { AvatarComponent } from 'ngx-avatars';
import { Alert, HwAlertService, ToastPosition } from '@globalshared/hw-alert-component/src/lib';

@AutoUnsubscribe()
@Component({
  selector: 'trustee-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SchedulerComponent implements OnInit, OnDestroy, ICanComponentDeactivate {

  @ViewChildren(TaskItemCardComponent) cards: QueryList<TaskItemCardComponent>;

  @ViewChild('supervisorSelector', { static: true } ) supervisorSelector :  AvatarComponent

  

  selectedUserGroup: string;
  selectedUsers: KipUser[] = [];
  loaded: boolean;

  froDatum: string;
  previousUrl: string;
  serverProcessing: boolean;
  dirty: boolean;
  editorForm: FormGroup;
  schedulerMode: string;
  filterOpen: boolean;
  userGroupsWithUsers: KipUserGroup[] = [];
  allCleaningScheduleItems: CleaningSchedule[];
  unassignedCleaningScheduleItems: CleaningSchedule[];
  assignedCleaningScheduleItems: CleaningSchedule[];
  allMaintenanceScheduleItems: vwMaintenanceSchedule[];
  unassignedMaintenanceScheduleItems: vwMaintenanceSchedule[];
  assignedMaintenanceScheduleItems: vwMaintenanceSchedule[];

  availableItems: CleaningSchedule[] = []
  allAvaliableItems: CleaningSchedule[] = [];
  allWorkItems: workItem[] = [];
  cleanigTypes: CleaningType[] = [];
  maintenanceTypes: MaintenanceType[]
  roomTypes: RoomType[] = [];
  placeTypes: SelectItem[];
  supervisors: KipUser[] = [];
  activeSupervisors : KipUser[]=[];

  users: KipUser[] = [];
  allUsers: KipUser[] = [];
  title: string;
  selectedSupervisorId: number = -1;


  currentGroupMembers: KipUser[] = [];


  loadFroDatum$: Subscription;
  hotelUnitChanged$: Subscription;
  saveCleaningSchedule$: Subscription;
  saveMaintenanceSchedule$: Subscription;


  routerEvents$: Subscription;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public sessionStorageService: SessionStorageService,
    private hokParamsService: HokParamsService,
    private kipUserService: KipUserService,
    private kipUserGroupService: KipUserGroupService,
    private translate: TranslateService,
    private topbarManagerService: TopBarManagerService,
    private routerExtraService: RouterExtraService,
    private cleaningTypeService: CleaningTypeService,
    private maintenanceTypeService: MaintenanceTypeService,
    private roomTypeService: RoomTypeService,
    private dailyTaskService: DailyTaskService,
    private roleHelperService: RoleHelperService,
    private crudMessageService: CrudMessagesService,
    private router: Router,
    private spinnerService: SpinnerService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    public styleHelper: StyleHelperService,
    private hwAlertService : HwAlertService,
    private confirmService: HwConfirmationDialogService,
    private schedulerService: SchedulerService,
    private fb: FormBuilder

  ) {

    this.editorForm = fb.group({
      'fPlaceType': new FormControl("-1"),
      'fRoomNumber': new FormControl(""),
      'fFloor': new FormControl(""),
      'fCleaningType': new FormControl("-1"),
      'fMaintenanceType': new FormControl("-1"),
      'fRoomType': new FormControl("-1"),
      'fAssignedTo': new FormControl(""),
      'fSupervisor': new FormControl(""),
      'AssignedTo': new FormControl([""]),
      'Supervisor': new FormControl([""])
    })

  }

  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
    this.routerEvents$ = this.activatedRoute.queryParams.subscribe(params => {
      let mode: string;
      if (params["mode"]) {
        mode = params["mode"].toString();
        switch (mode.toUpperCase()) {
          case "C":
          case 'M':
            {
              this.schedulerMode = mode.toUpperCase();
              this.title = this.schedulerMode == "C" ? this.translate.instant("K701p.title") : this.translate.instant("K705p.title")
              break;
            }
          default:
            this.authService.gotoLogin();
        }
      } else {
        this.authService.gotoLogin();
      }
    })
    this.loadAll();
    this.hotelUnitChanged$ = this.topbarManagerService.hotelUnitChanged.subscribe(event => {
      this.loadAll();
    })

    this.schedulerService.AssignedToChanged.subscribe( result=> {
      if(result.Type == "M") {
        this.allMaintenanceScheduleItems.find(item=>item.MaintenanceScheduleId == result.Id).AssignedTo = result.AssignedTo;
        this.allMaintenanceScheduleItems.find(item=>item.MaintenanceScheduleId == result.Id).SuperVisorId = result.Supervisor;
      } else {
        this.allCleaningScheduleItems.find(item=>item.CleaningScheduleId == result.Id).AssignedTo = result.AssignedTo;
        this.allCleaningScheduleItems.find(item=>item.CleaningScheduleId == result.Id).SuperVisorId = result.Supervisor;
      }
    })

    this.schedulerService.SupervisorChanged.subscribe(result=> {
      if(result.Type == "M") {
        this.allMaintenanceScheduleItems.find(item=>item.MaintenanceScheduleId == result.Id).SuperVisorId = result.Supervisor;
        this.allMaintenanceScheduleItems.find(item=>item.MaintenanceScheduleId == result.Id).AssignedTo = result.AssignedTo;
      } else {
        this.allCleaningScheduleItems.find(item=>item.CleaningScheduleId == result.Id).SuperVisorId = result.Supervisor;
        this.allCleaningScheduleItems.find(item=>item.CleaningScheduleId == result.Id).AssignedTo = result.AssignedTo;
      }
    })  

  }
  ngOnDestroy(): void {
    this.loadFroDatum$?.unsubscribe();
    this.saveCleaningSchedule$?.unsubscribe;
    this.saveMaintenanceSchedule$?.unsubscribe();
    this.hotelUnitChanged$?.unsubscribe();
    this.destroy$?.unsubscribe();
  }
  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  exportToPdf() {
    if (this.schedulerMode == "C") {
      this.saveCleaningSchedule$ = this.dailyTaskService.saveCleaningScheduleItems(this.allCleaningScheduleItems).subscribe(result => {
        var res = result as RequestResult;
        this.crudMessageService.showAddOrEditMessage(res);
        if (res.Code == 0) {
          this.exportPdf();
        }
      })
    }
    else {
      this.saveMaintenanceSchedule$ = this.dailyTaskService.saveMaintenanceScheduleItems(this.allMaintenanceScheduleItems).subscribe(result => {
        var res = result as RequestResult;
        this.crudMessageService.showAddOrEditMessage(res);
        if (res.Code == 0) {
          this.exportPdf();
        }
      })
    }

  }
  exportPdf() {
    this.serverProcessing = true;

    const fileName = this.title + "-" + this.froDatum + ".pdf";
    let filter: vwTaskListFilter = new vwTaskListFilter();

    filter.MyTasksOnly = false;
    filter.TaskStateCreated = true;
    filter.TaskStatePaused = true;
    filter.TaskStateEnded = true;
    filter.TaskStateRunning = true;
    filter.TaskStateDeleted = true;
    filter.TaskStateAccepted = true;
    filter.AssignedTo = -1  // "-1" az összes felhasználóhoz rendelt feladat,  egyébként  az AssignedTo ben meghatározott user feladatai  
    filter.ItemType = "0";

    if (this.schedulerMode == "C") {
      this.dailyTaskService.getTaskListPdf(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId(), filter).subscribe(fileData => {
        let b: any = new Blob([fileData], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(b);
        window.open(fileURL);
        this.serverProcessing = false;
        saveAs(b, fileName),
          error => {
            this.serverProcessing = false;
          };

      })
    } else {
      this.dailyTaskService.getScheduleListPdf(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId(), filter).subscribe(fileData => {
        let b: any = new Blob([fileData], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(b);
        window.open(fileURL);
        this.serverProcessing = false;
        saveAs(b, fileName),
          error => {
            this.serverProcessing = false;
          };

      })

    }


  }
  saveAll(fromGuard: boolean) {
    this.spinnerService.setSpinnerVisibility(true);
    if (this.schedulerMode == "C") {
      const toSaveCleaning: CleaningSchedule[] = [...this.assignedCleaningScheduleItems, ...this.unassignedCleaningScheduleItems];

      this.saveCleaningSchedule$ = this.dailyTaskService.saveCleaningScheduleItems(this.allCleaningScheduleItems).subscribe(result => {
        var res = result as RequestResult;
        this.crudMessageService.showAddOrEditMessage(res);
        if (res.Code == 0 && fromGuard) {
          this.spinnerService.setSpinnerVisibility(false);
          this.router.navigateByUrl(this.previousUrl);
        }
        this.dirty = false;
      })
    } else {
      const toSaveMaintenance: vwMaintenanceSchedule[] = [...this.assignedMaintenanceScheduleItems, ...this.unassignedMaintenanceScheduleItems];
      this.saveMaintenanceSchedule$ = this.dailyTaskService.saveMaintenanceScheduleItems(toSaveMaintenance).subscribe(result => {
        var res = result as RequestResult;
        this.crudMessageService.showAddOrEditMessage(res);
        if (res.Code == 0 && fromGuard) {
          this.spinnerService.setSpinnerVisibility(false);
          this.router.navigateByUrl(this.previousUrl);
        }
        this.dirty = false;
      })
    }

  }

  toggleGroupSelection(event: MatCheckboxChange, group: KipUserGroup) {
    let tmpArr: KipUser[] = [];

    if (event.checked==true) {
      if (group.UserGroupId == -1) {
        if (event.checked == true) {
          this.selectedUsers = [...this.allUsers];
          this.editorForm.controls["AssignedTo"].setValue(this.selectedUsers);
        } else {
          this.selectedUsers = [];
          this.editorForm.controls["AssignedTo"].setValue([]);
        }
      } else {
        let oldValues = this.editorForm.controls["AssignedTo"].value as KipUser[];
        oldValues = oldValues.filter(item=>item["UserId"] !== undefined)
        group.Users.forEach(user=> {
          if(!oldValues.find(item=>item.UserId == user.UserId && group.Users?.length>0)) {
            oldValues.push(user)
          }
        })
        this.editorForm.controls["AssignedTo"].setValue(oldValues);
        this.selectedUsers=[];
        this.selectedUsers = [...oldValues];
      }
    } else {
      const oldValues = this.editorForm.controls["AssignedTo"].value as Array<KipUser>;
      group.Users.forEach(user=> {
        const ind = oldValues.findIndex(item=>item.UserId == user.UserId);
        if( ind != -1)
          oldValues.splice(ind,1)
       })
       this.editorForm.controls["AssignedTo"].setValue(oldValues);
       this.selectedUsers=[];
       this.selectedUsers = [...oldValues];
    }
  }
  selectUser() {
    this.selectedUsers = this.editorForm.controls["AssignedTo"].value as Array<KipUser>;
  }
  toggleAllUsers(event: MatCheckboxChange) {
    if (event.checked) {
      this.selectedUsers = [...this.allUsers];
      this.editorForm.controls["AssignedTo"].setValue(this.selectedUsers);
    } else {
      this.selectedUsers = [];
      this.editorForm.controls["AssignedTo"].setValue([]);
    }

  }
  selectSupervisor(user: KipUser) {
    this.selectedSupervisorId = user.UserId;
  }


  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================

  /* assigned user header functions*/
  sumRoomForCleaning(userId: number): number {
    return this.allCleaningScheduleItems.filter(item => item.AssignedTo == userId).length;
  }
  sumTimeForCleaning(userId: number): number {
    let s: number = 0;
    this.allCleaningScheduleItems.filter(item => item.AssignedTo == userId).forEach(i => {
      s += i.SumTimeLimit;
    })
    return s;
  }

  sumByRoomStates(userId: number): SumsByRoomState {
    let sumsByRoomState: SumsByRoomState = new SumsByRoomState();

    const filtered = this.allCleaningScheduleItems.filter(item => item.AssignedTo == userId);
    filtered.forEach(item => {
      switch (item.RooomState) {
        case RoomState.EMPTY_AVAILABLE:
        case RoomState.RESERVED_OK:
          sumsByRoomState.Ok += 1;
          break;
        case RoomState.EMPTY_CLEAN:
        case RoomState.RESERVED_CLEAN:
          sumsByRoomState.Clean += 1;
          break;
        case RoomState.EMPTY_DIRTY:
        case RoomState.RESERVED_DIRTY:
          sumsByRoomState.Dirty += 1;
          break;
        case RoomState.OUT_OF_SERVICE:
          sumsByRoomState.OOS += 1;
          break;
        case RoomState.OUT_OF_ORDER:
          sumsByRoomState.OOO += 1;
          break;
        case RoomState.SHOW_ROOM:
          sumsByRoomState.ShowRoom += 1;
      }
    })
    return sumsByRoomState;

  }

  sumTaskForMaintenance(userId: number): number {
    return this.allMaintenanceScheduleItems.filter(item => item.AssignedTo == userId).length
  }
  recalSumsByRommstates() { }

  /* dragdrop functions */

  filteredAssignedCleaningScheduleItems(userId: number): CleaningSchedule[] {
    const isActive = this.allUsers.find(item=>item.UserId == userId).Active;
    return this.allCleaningScheduleItems.filter(item => item.AssignedTo == userId).sort((a, b) => (a.ExecutionOrder > b.ExecutionOrder ? 1 : -1))
  }

  filteredUnAssignedCleaningScheduleItems(): CleaningSchedule[] {
    let filtered = this.allCleaningScheduleItems.filter(item => item.AssignedTo == null)
    var mustInclude: boolean;
    filtered = filtered.filter(item => (this.editorForm.controls["fPlaceType"].value == "-1" || (this.editorForm.controls["fPlaceType"].value === item.AreaTypeCode)))
    filtered = filtered.filter(item => item.AreaNumber.toLowerCase().startsWith(this.editorForm.controls["fRoomNumber"].value.toString().toLowerCase()) || this.editorForm.controls["fRoomNumber"].value == "")
    filtered = filtered.filter(item => item.Floor.toLowerCase().startsWith(this.editorForm.controls["fFloor"].value.toString().toLowerCase()) || this.editorForm.controls["fFloor"].value == "")
    filtered = filtered.filter(item => (this.editorForm.controls["fCleaningType"].value == "-1" || this.editorForm.controls["fCleaningType"].value == item.CleaningTypeCode))
    filtered = filtered.filter(item => (this.editorForm.controls["fRoomType"].value == "-1" || this.editorForm.controls["fRoomType"].value == item.RoomType))
    return filtered;
  }

  dragStart(event: CdkDragStart) {
    if(this.selectedSupervisorId == -1) {
      this.hwAlertService.error(this.translate.instant('common.choose_a_supervisor'), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
    }
  }
  filteredAssignedMaintenanceScheduleItems(userId: number): vwMaintenanceSchedule[] {
    return this.allMaintenanceScheduleItems.filter(item => item.AssignedTo == userId).sort((a, b) => (a.ExecutionOrder > b.ExecutionOrder ? 1 : -1))
  }

  filteredUnAssignedMaintenanceScheduleItems(): vwMaintenanceSchedule[] {

    return this.allMaintenanceScheduleItems.filter(item => item.AssignedTo == null).sort((a, b) => (a.ExecutionOrder > b.ExecutionOrder ? 1 : -1))
  }

  sortPredicate(index: number, item: CdkDrag<CleaningSchedule>) {
    if ((item.data as CleaningSchedule).ExecutionOrder == index) {

    }
  }


  assignCleaningToUser(event: CdkDragDrop<CleaningSchedule[]>, userId: number) {
    // adott  konténeren  belül mozgat, csak index csere van
    if (event.previousContainer === event.container) {
      moveItemInArray((event.container.data), event.previousIndex, event.currentIndex);
      for (let i = 0; i < event.container.data.length; i++) {
        this.allCleaningScheduleItems.find(item => item.CleaningScheduleId == event.container.data[i].CleaningScheduleId).ExecutionOrder = i;
        event.container.data[i].ExecutionOrder = i;
      }
    } else {
      if(this.selectedSupervisorId !== -1) {
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex,
        );
        event.container.data[event.currentIndex].AssignedTo = userId;
        event.container.data[event.currentIndex].SuperVisorId = this.selectedSupervisorId;
        this.dirty = true;
      } 
      //this.allCleaningScheduleItems.find(item => item.CleaningScheduleId).AssignedTo = null;
    }
   
  }
  unassignCleaning(event: CdkDragDrop<CleaningSchedule[]>) {
    event.previousContainer.data[event.previousIndex].AssignedTo = null;
    if (event.container.data[event.currentIndex]) {
      event.container.data[event.currentIndex].AssignedTo = null;
    }

    //this.allCleaningScheduleItems.find(item => item.CleaningScheduleId).AssignedTo = null;
    transferArrayItem(
      event.previousContainer.data,
      event.container.data,
      event.previousIndex,
      event.currentIndex,
    );

    // rá sem dobta, azonnal visszarakja
    if (event.container.data[event.currentIndex].AssignedTo !== null) {
      for (let i = 0; i < event.previousContainer.data.length; i++) {
        this.allCleaningScheduleItems.find(item => item.CleaningScheduleId == event.previousContainer.data[i].CleaningScheduleId).ExecutionOrder = i;
        event.previousContainer.data[i].ExecutionOrder = i;

      }
    }
    this.dirty = true;
  }
  assignMaintenanceToUser(event: CdkDragDrop<vwMaintenanceSchedule[]>, userId: number) {
    if (event.previousContainer === event.container) {
      moveItemInArray((event.container.data), event.previousIndex, event.currentIndex);
      for (let i = 0; i < event.container.data.length; i++) {
        this.allMaintenanceScheduleItems.find(item => item.MaintenanceScheduleId == event.container.data[i].MaintenanceScheduleId).ExecutionOrder = i;
        event.container.data[i].ExecutionOrder = i;
      }
    } else {
      if(this.selectedSupervisorId !== -1) { 
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex,
        );
        event.container.data[event.currentIndex].AssignedTo = userId;
        event.container.data[event.currentIndex].SuperVisorId = this.selectedSupervisorId;
        this.dirty = true;
      }


    }
  }
  unassignMaintenance(event: CdkDragDrop<vwMaintenanceSchedule[]>) {
    event.previousContainer.data[event.previousIndex].AssignedTo = null;
    if (event.container.data[event.currentIndex]) {
      event.container.data[event.currentIndex].AssignedTo = null;
    }

    //this.allMaintenanceScheduleItems.find(item => item.MaintenanceScheduleId).AssignedTo = null;
    transferArrayItem(
      event.previousContainer.data,
      event.container.data,
      event.previousIndex,
      event.currentIndex,
    );

    // rá sem dobta, azonnal visszarakja
    if (event.container.data[event.currentIndex].AssignedTo !== null) {
      for (let i = 0; i < event.previousContainer.data.length; i++) {
        this.allMaintenanceScheduleItems.find(item => item.MaintenanceScheduleId == event.previousContainer.data[i].MaintenanceScheduleId).ExecutionOrder = i;
        event.previousContainer.data[i].ExecutionOrder = i;

      }
      this.dirty = true;
    }
  }



  loadAll() {

    let unit: string;
    let hotel: number;
    this.activeSupervisors=[];
    if (this.sessionStorageService.multiHotel) {
      const hot: SelectItem[] = this.sessionStorageService.getAllHotels();
      unit = hot[0].value.toString().split('@')[0]
      hotel = hot[0].value.toString().split('@')[1]

    } else { // nem multihotel, csak a kiválasztott
      unit = this.sessionStorageService.getSelectedUnit();
      hotel = this.sessionStorageService.getSelectedHotelId()
    }

    this.hokParamsService.loadFroDatum(unit, hotel).subscribe(hokResult => {
      let hokRes = hokResult as RequestResult;
      this.froDatum = hokRes.Data;
      this.loadCodeTables()
    })
  }

  loadCodeTables() {
    this.spinnerService.setSpinnerVisibility(true);
    if (this.schedulerMode == "M") {
      combineLatest([
        this.loadAllKipUserGroup(),
        this.loadUsersWithRoles(),
        this.loadKipUsersByGroup(),
        this.loadMaintenanceScheduleItems(),
        this.loadMaintenanceTypes(),
        this.loadAllRoomTypes()
      ]).subscribe(([allKipUserGroup, allUserWithRoles, allKipUsersByGroup, allMaintenanceScheduleItems, allMaintenanceTypes, allRoomTypes]) => {
        let ugRes = allUserWithRoles as RequestResult;
        this.supervisors = [];
        var tr: vwKipUserRoles[] = ugRes.Data as vwKipUserRoles[];
        tr.forEach(item => {
          if (this.roleHelperService.hasRole(item.Role, RoleType.MUSZAKI_VEZETO)) {
            var newTechLead = new KipUser();
            newTechLead.UserId = item.UserId;
            newTechLead.RealName = item.RealName;
            newTechLead.HotelId = item.HotelId;
            newTechLead.Subscriber = item.Subscriber;
            newTechLead.Active = item.Active;
            this.supervisors.push(newTechLead);
            if( item.Active) {
              this.activeSupervisors.push(newTechLead) 
            }
          }
          // if (this.supervisors.length > 0) {
          //   this.editorForm.controls["AssignedTo"].patchValue([this.supervisors[0]])
          // }
        })
        if (this.supervisors.length > 0) {
          this.selectedSupervisorId ==  -1 //this.supervisors[0].UserId;
        }
        //--
        let ugIres = allKipUsersByGroup as RequestResult;
        this.userGroupsWithUsers = ugIres.Data as Array<KipUserGroup>;
        this.allUsers = [];
        this.userGroupsWithUsers.forEach(group => {
          group.Users.forEach(user => {
            this.allUsers.push(user)
          })
        })
        // this.userGroupsWithUsers.splice(0, 0, { UserGroupId: -1, UserGroupName: this.translate.instant("common.everybody"), HotelId: 1, Subscriber: '', Users: [] })

        let maintRes = allMaintenanceScheduleItems as RequestResult;

        this.allMaintenanceScheduleItems = maintRes.Data as Array<vwMaintenanceSchedule>;
        this.refreshMaintenanceItems();



        this.placeTypes = [
          { label: this.translate.instant("common.all"), value: "-1" },
          { label: this.translate.instant("common.room"), value: "R" },
          { label: this.translate.instant("common.public_area"), value: "P" }

        ]

        //---
        this.maintenanceTypes = allMaintenanceTypes.Data as Array<MaintenanceType>
        let typeCodeForAll: MaintenanceType = new MaintenanceType();
        typeCodeForAll.TypeCode = "-1";
        typeCodeForAll.TypeName = this.translate.instant("common.all");
        this.maintenanceTypes.splice(0, 0, typeCodeForAll)
        //
        this.roomTypes = allRoomTypes.Data as Array<RoomType>;
        let roomTypeForAll: RoomType = new RoomType();
        roomTypeForAll.RoomType = "-1";
        roomTypeForAll.RoomTypeName = this.translate.instant("common.all");
        this.roomTypes.splice(0, 0, roomTypeForAll);
        this.editorForm.controls["fRoomType"].patchValue("-1");
        this.editorForm.controls["fMaintenanceType"].patchValue("-1");
        this.applyMaintenanceFilter();
        this.spinnerService.setSpinnerVisibility(false);
        //this.supervisorSelector.value = "-1"
        this.loaded = true;
      })
    } else {
      combineLatest([
        this.loadAllKipUserGroup(),
        this.loadUsersWithRoles(),
        this.loadKipUsersByGroup(),
        this.loadCleaningScheduleItems(),
        this.loadCleaningTypes(),
        this.loadAllRoomTypes()
      ]).subscribe(([allKipUserGroup, allUserWithRoles, allKipUsersByGroup, allCleaningScheduleItems, allCleaningTypes, allRoomTypes]) => {
        let ugRes = allUserWithRoles as RequestResult;
        this.supervisors = [];
        var tr: vwKipUserRoles[] = ugRes.Data as vwKipUserRoles[];
        tr.forEach(item => {
          if (this.roleHelperService.hasRole(item.Role, RoleType.GONDNOKNO)) {
            var newTrusTee = new KipUser();
            newTrusTee.UserId = item.UserId;
            newTrusTee.RealName = item.RealName;
            newTrusTee.HotelId = item.HotelId;
            newTrusTee.Subscriber = item.Subscriber;
            newTrusTee.Active = item.Active;
            this.supervisors.push(newTrusTee);
            if(item.Active) {
              this.activeSupervisors.push(newTrusTee);
            }
          }
        })
        // this.supervisors.find(item=>item.UserId==28).Active=false;
        if (this.supervisors.length > 0) {
          this.selectedSupervisorId = -1;  //this.supervisors[0].UserId;
        }
        //--
        let ugIres = allKipUsersByGroup as RequestResult;
        this.userGroupsWithUsers = ugIres.Data as Array<KipUserGroup>;
        this.allUsers = [];
        this.userGroupsWithUsers.forEach(group => {
          group.Users.forEach(user => {
            this.allUsers.push(user)
          })
        })
        // this.allUsers.find(item=>item.UserId==28).Active=false;
        
        let cleanRes = allCleaningScheduleItems as RequestResult;

        this.allCleaningScheduleItems = cleanRes.Data as Array<CleaningSchedule>;
        this.resfreshCleaningItems();


        this.placeTypes = [
          { label: this.translate.instant("common.all"), value: "-1" },
          { label: this.translate.instant("common.room"), value: "R" },
          { label: this.translate.instant("common.public_area"), value: "P" }

        ]

        //---
        this.cleanigTypes = allCleaningTypes.Data as Array<CleaningType>
        let typeCodeForAll: CleaningType = new CleaningType();
        typeCodeForAll.TypeCode = "-1";
        typeCodeForAll.TypeName = this.translate.instant("common.all");
        this.cleanigTypes.splice(0, 0, typeCodeForAll)
        //
        this.roomTypes = allRoomTypes.Data as Array<RoomType>;
        let roomTypeForAll: RoomType = new RoomType();
        roomTypeForAll.RoomType = "-1";
        roomTypeForAll.RoomTypeName = this.translate.instant("common.all");
        this.roomTypes.splice(0, 0, roomTypeForAll);
        this.editorForm.controls["fRoomType"].patchValue("-1");
        this.editorForm.controls["fCleaningType"].patchValue("-1");
        this.applyCleaningFilter();
        this.spinnerService.setSpinnerVisibility(false);
        this.loaded = true;
        // this.userGroupChanged(0);

      })
    }


  }
  /*  combineLatest functions */
  loadAllKipUserGroup(): Observable<any> {
    let units: string;
    let hotels: string;
    if (this.sessionStorageService.multiHotel) {
      if (!this.sessionStorageService.IgnoreMultiHotel) {
        if (this.sessionStorageService.getSelectedHotelId() === 99) { //   99,1,4,10 
          units = this.sessionStorageService.getSelectedUnitMulti();
          hotels = this.sessionStorageService.getSelectedHotelIdMulti();
        } else { // 99, és a konkrét szálloda
          const sel: string[] = this.sessionStorageService.getFlattenedHotels()[0].value.toString().split("@")
          units = sel[0] + ',' + this.sessionStorageService.getSelectedUnit();
          hotels = sel[1] + ',' + this.sessionStorageService.getSelectedHotelId().toString()
        }
      }
    } else { // nem multihotel, csak a kiválasztott
      units = this.sessionStorageService.getSelectedUnit();
      hotels = this.sessionStorageService.getSelectedHotelId().toString();
    }
    return this.kipUserGroupService.loadAllMulti(units, hotels).pipe(map((result) => {
      const res = result as RequestResult;
      const users = res.Data as KipUserGroup[];
      if (users.length > 0) {
        this.selectedUserGroup = users[0].UserGroupId.toString();
      }

      return result;
    }));
  }
  loadUsersWithRoles(): Observable<any> {
    let units: string;
    let hotels: string;
    if (this.sessionStorageService.multiHotel) {
      if (!this.sessionStorageService.IgnoreMultiHotel) {
        if (this.sessionStorageService.getSelectedHotelId() === 99) { //   99,1,4,10 
          units = this.sessionStorageService.getSelectedUnitMulti();
          hotels = this.sessionStorageService.getSelectedHotelIdMulti();
        } else { // 99, és a konkrét szálloda
          const sel: string[] = this.sessionStorageService.getFlattenedHotels()[0].value.toString().split("@")
          units = sel[0] + ',' + this.sessionStorageService.getSelectedUnit();
          hotels = sel[1] + ',' + this.sessionStorageService.getSelectedHotelId().toString()
        }
      }
    } else { // nem multihotel, csak a kiválasztott
      units = this.sessionStorageService.getSelectedUnit();
      hotels = this.sessionStorageService.getSelectedHotelId().toString();
    }
    return this.kipUserService.loadUsersWithRolesMulti(units, hotels).pipe(map((result) => {
      return result;
    }));
  }
  // majd multira átírni
  loadKipUsersByGroup(): Observable<any> {

    return this.kipUserGroupService.loadAllMulti(this.sessionStorageService.getSelectedUnitMulti(),
      this.sessionStorageService.getSelectedHotelIdMulti(),
    ).pipe(map((result) => {
      return result;
    }));
  }
  loadCleaningScheduleItems(): Observable<any> {
    const hot: SelectItem[] = this.sessionStorageService.getAllHotels();

    let unitsArr = [];
    let hotelsArr = [];
    let units: string;
    let hotels: string;
    if (this.sessionStorageService.getSelectedHotelId() === 99) {
      hot.forEach(item => {
        unitsArr.push(item.value.toString().split("@")[0])
        hotelsArr.push(item.value.toString().split("@")[1])
      })
      units = unitsArr.join(',');
      hotels = hotelsArr.join(',');
    }
    else {
      units = this.sessionStorageService.getSelectedUnit();
      hotels = this.sessionStorageService.getSelectedHotelId().toString();
    }
    return this.dailyTaskService.getCleaningScheduleItems(units, hotels, this.froDatum).pipe(map((result) => {
      return result;
    }));
  }
  loadCleaningTypes(): Observable<any> {
    const units = this.sessionStorageService.getSelectedUnitMulti();
    const hotels = this.sessionStorageService.getSelectedHotelIdMulti();
    return this.cleaningTypeService.loadAllMulti(units, hotels).pipe(map((result) => {
      return result;
    }));
  }
  loadMaintenanceTypes(): Observable<any> {
    const units = this.sessionStorageService.getSelectedUnitMulti();
    const hotels = this.sessionStorageService.getSelectedHotelIdMulti();
    return this.maintenanceTypeService.loadAllMulti(units, hotels).pipe(map((result) => {
      return result;
    }));
  }
  loadAllRoomTypes(): Observable<any> {
    const units = this.sessionStorageService.getSelectedUnitMulti();
    const hotels = this.sessionStorageService.getSelectedHotelIdMulti();
    return this.roomTypeService.loadAllMulti(units, hotels).pipe(map((result) => {
      return result;
    }));

  }
          
  loadMaintenanceScheduleItems(): Observable<any> {
    const hot: SelectItem[] = this.sessionStorageService.getAllHotels();

    let unitsArr = [];
    let hotelsArr = [];
    let units: string;
    let hotels: string;
    if (this.sessionStorageService.getSelectedHotelId() === 99) {
      hot.forEach(item => {
        unitsArr.push(item.value.toString().split("@")[0])
        hotelsArr.push(item.value.toString().split("@")[1])
      })
      units = unitsArr.join(',');
      hotels = hotelsArr.join(',');
    }
    else {
      units = this.sessionStorageService.getSelectedUnit();
      hotels = this.sessionStorageService.getSelectedHotelId().toString();
    }
    return this.dailyTaskService.getMaintenanceScheduleItems(units, hotels, this.froDatum).pipe(map((result) => {
      return result;
    }));
  }

  applyCleaningFilter() {
    var tmpItems: CleaningSchedule[] = [];
    var mustInclude: boolean;

    this.allCleaningScheduleItems.filter(allItem => allItem.AssignedTo == null).forEach(item => {
      mustInclude = true;
      if (!(this.editorForm.controls["fPlaceType"].value !== "-1" && (this.editorForm.controls["fPlaceType"].value === item.AreaTypeCode))) {
        mustInclude = false;
      }
      if (!(this.editorForm.controls["fPlaceType"].value !== "-1" && (this.editorForm.controls["fPlaceType"].value === item.AreaTypeCode))) {
        mustInclude = false;
      }
      if ((this.editorForm.controls["fPlaceType"].value == "-1")) {
        mustInclude = true;
      }

      if (!item.AreaNumber.toLowerCase().startsWith(this.editorForm.controls["fRoomNumber"].value.toString().toLowerCase()) &&
        this.editorForm.controls["fRoomNumber"].value !== "" && mustInclude) {
        mustInclude = false;
      }

      if (!item.Floor.toLowerCase().startsWith(this.editorForm.controls["fFloor"].value.toString().toLowerCase()) &&
        this.editorForm.controls["fFloor"].value !== "" && mustInclude) {
        mustInclude = false;
      }

      if ((this.editorForm.controls["fCleaningType"].value !== "-1" && this.editorForm.controls["fCleaningType"].value !== item.CleaningTypeCode && mustInclude)) {
        mustInclude = false;
      }

      if ((this.editorForm.controls["fRoomType"].value !== "-1" && this.editorForm.controls["fRoomType"].value !== item.RoomType && mustInclude)) {
        mustInclude = false;
      }

      if (mustInclude && (item.AssignedTo == null)) {
        tmpItems.push(item);
      }
      this.unassignedCleaningScheduleItems = [...tmpItems];
    })
  }

  applyMaintenanceFilter() {

  }
  resfreshCleaningItems() {
    this.unassignedCleaningScheduleItems = this.allCleaningScheduleItems.filter(item => item.AssignedTo == null);
    this.assignedCleaningScheduleItems = this.allCleaningScheduleItems.filter(item => item.AssignedTo !== null);
  }

  refreshMaintenanceItems() {
    this.unassignedMaintenanceScheduleItems = this.allMaintenanceScheduleItems.filter(item => item.AssignedTo == null);
    this.assignedMaintenanceScheduleItems = this.allMaintenanceScheduleItems.filter(item => item.AssignedTo !== null);
  }

  canExit(): boolean | UrlTree {

    if (this.dirty) {
      if (confirm(this.translate.instant("common.exit_without_save"))) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }


  /* style functions */
  avatarBackColor(user: KipUser) {
    if(!user.Active) {
      return "#F0F0F0"
    }
    if (user.UserId == this.selectedSupervisorId) {
      return "#3f51b5";
    } else {
      return "#03a9f4"
    }
  }

  isInactive( user: KipUser) {
    let styles={};
    styles["cursor"] = user.Active ? 'pointer' : 'none';
    return styles;  
  }

  isUserActive( sch: CdkDrag<CleaningSchedule>) : boolean {
    return this.allUsers.find(item => item.UserId== sch.data.AssignedTo).Active
   
  }

  dropListEnterPredicate( userId: number) {
    const isActive = this.allUsers.find(item=>item.UserId == userId).Active;
    return function(drag: CdkDrag, drop: CdkDropList) {
      return isActive;
  };
  }

  //#endregion =================================================================================================================





}

enum DraggedItemType {
  Unassigned = 1,
  Assigned = 2
}
class workItem {
  Maid: KipUser;
  CleaningItems: CleaningSchedule[];

}

class SumsByRoomState {
  UserId: number = 0;
  Clean: number = 0;
  Dirty: number = 0;
  Ok: number = 0;
  OOO: number = 0;
  OOS: number = 0;
  ShowRoom: number = 0;
}

