// Angular

import { LOCATION_INITIALIZED, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import jwt_decode from "jwt-decode";
// 3rdparty

import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/hu';
import 'dayjs/locale/th';
import 'dayjs/locale/uk';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isLeapYear from 'dayjs/plugin/isLeapYear';

import localeDe from '@angular/common/locales/de';
import { default as localeEn, default as localeUk } from '@angular/common/locales/en';
import localeHu from '@angular/common/locales/hu';


import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { provideNgxMask } from 'ngx-mask';

// Hostware
import { DirectivesModule } from '../../../shared/directives/DirectivesModule';
import { HwAlertService } from '../../../shared/hw-alert-component/src/lib/hw-alert.service';
import { MatDayjsDateModule } from '../../../shared/hw-material-dayjs-adapter/lib/adapter/';
import { HwDayjsDateAdapter, MAT_DAYJS_DATE_ADAPTER_OPTIONS } from '../../../shared/hw-material-dayjs-adapter/lib/adapter/hw-dayjs-date-adapter';

import { HwConfirmationDialogModule } from '../../../shared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.module';
import { HwConfirmationDialogService } from '../../../shared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.service';


import { ChatModule } from '@globalshared/chat/src/lib/chat.module';
import { HwAlertModule } from '@globalshared/hw-alert-component/src/lib/hw-alert.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { CoreModule } from "./core/core.module";
import { MaterialModule } from './core/material.module';
import { HwAuthInterceptorService } from './core/services/hw-auth-interceptor.service';
import { UserService } from './core/services/user.service';
import { HttpLoaderFactory, KipModule } from './kip/kip.module';
import { CommonModule } from '@angular/common';
import { SystemMessagesViewerModule } from '@globalshared/system-messages-viewer/src/lib/system-messages-viewer.module'
// import { SystemMessagesViewerService } from '@globalshared/system-messages-viewer/src/lib/system-messages-viewer.service';

/* dayjs */



import { ActivatedRoute, Router } from '@angular/router';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/hu';
import 'dayjs/locale/ru';
import 'dayjs/locale/uk';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { AuthService } from './core/services/auth.service';
import { AuthItem } from './core/interfaces/auth-item';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ServiceWorkerBypassInterceptor } from './interceptors/hw-service-worker-bypass-interceptor';

dayjs.extend(customParseFormat);
dayjs.extend(isLeapYear);

registerLocaleData(localeHu, 'hu');
registerLocaleData(localeEn, 'en');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeUk, 'uk');
registerLocaleData(localeEn, 'en');


export const HU_FORMATS = {
  parse: {
    dateInput: 'YYYY.MM.DD',
  },
  display: {
    dateInput: 'YYYY.MM.DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,

  ],
  imports: [
    MaterialModule,
    CommonModule,
    ChatModule,
    SocketIoModule.forRoot({ url: environment.chatBackendUrl } as SocketIoConfig),
    KipModule,
    HwAlertModule,
    DirectivesModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    HttpClientModule,
    FormsModule,
    HwAlertModule,
    ReactiveFormsModule,
    HwConfirmationDialogModule,
    SystemMessagesViewerModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { 
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000'
    }),
    TranslateModule.forRoot({
      defaultLanguage: "hu",
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatDayjsDateModule,
    CurrencyMaskModule
  ],
  exports: [
    CoreModule,
    MatDayjsDateModule,
    CurrencyMaskModule,
    KipModule,
    HwAlertModule,
    ServiceWorkerModule,
    MaterialModule,
    SystemMessagesViewerModule
  ],
  providers: [
    HwConfirmationDialogService,
    HwAlertService,
    HwConfirmationDialogService,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    UserService,
    // SystemMessagesViewerService,{ provide: 'environment', useValue: environment},
    provideNgxMask(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HwAuthInterceptorService,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'hu' },
    { provide: MAT_DATE_LOCALE, useValue: 'hu-HU' },
    { provide: MAT_DATE_FORMATS, useValue: HU_FORMATS },
    { provide: HTTP_INTERCEPTORS, useClass: HwAuthInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ServiceWorkerBypassInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    },
    { provide: MAT_DAYJS_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
    {
      provide: DateAdapter,
      useValue: HwDayjsDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_DAYJS_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DAYJS_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
  ],



  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(private router: Router, route: ActivatedRoute, authService: AuthService) {
    // let a = route;
    if (!authService.isAuthTokenValid()) {
      router.navigate(["/login"], { relativeTo: route })
    }

  }
}

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {

      let langToSet = 'hu';
      translate.addLangs(['hu', 'en', 'uk']);
      const token = sessionStorage.getItem('hw-kip-token');
      if (!Boolean(token)) {
        langToSet = 'hu'
      } else {
        const authItem = <AuthItem>JSON.parse(token)
        let decodedToken = jwt_decode(authItem.access_token);
        langToSet = decodedToken["Culture"];
      }

      // translate.setDefaultLang(langToSet);
      // translate.use(langToSet);
      // const browserLang = translate.getBrowserLang();
      // if (environment.home) {
      //   langToSet = 'hu';
      // } else {
      //   langToSet = browserLang.match(/hu|en/) ? browserLang : 'hu';
      // }
      translate.setDefaultLang(langToSet);
      translate.use(langToSet).subscribe(() => {
        console.info(`Successfully initialized '${langToSet}' language.'`);
      }, err => {
        console.error(`Problem with '${langToSet}' language initialization.'`);
      }, () => {
        resolve(null);
      });
    });
  });
}



