
import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PwaService {

  @Output() addToHomeScreen : EventEmitter<null> = new EventEmitter();

  constructor() { }

}