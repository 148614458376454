// Angular
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
// 3rdparty
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
// Hostware
import { LoginService } from '@app/core/services/login.service';
import { Alert, ToastPosition } from '@globalshared/hw-alert-component/src/lib/hw-alert.model';
import { HwAlertService } from '@globalshared/hw-alert-component/src/lib/hw-alert.service';
import { DailyTaskService } from '../../../kip/services/daily-task.service';
import { RabbitLogService } from '../../../kip/services/rabbit-log.service';
import { RequestResult } from '../../dto/RequestResult';
import { UserInfo } from '../../dto/UserInfo';
import { AuthService } from '../../services/auth.service';
import { SessionStorageService } from '../../services/session-storage.service';
import { TopBarManagerService } from '../../services/topbar-manager.service';
import { UserService } from '../../services/user.service';
import { HwConfirmationDialogService } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.service';
import { HwConfirmationDialogData } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.component';
import { SystemMessagesViewerService } from '@globalshared/system-messages-viewer/src/lib/system-messages-viewer.service';
import { SystemMessagesViewerComponent} from '@globalshared/system-messages-viewer/src/lib/system-messages-viewer.component'
import {SystemMessageDto} from '@globalshared/system-messages-viewer/src/lib/SystemMessageDto'
import { MatDialog } from '@angular/material/dialog';
import { PwaService } from '@app/core/services/pwa.service';

@AutoUnsubscribe()
@Component({
  selector: 'topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit,OnDestroy {

  @Output() toggle: EventEmitter<null> = new EventEmitter();
  @Output() toggleMenu: EventEmitter<null> = new EventEmitter();

  loggedIn : boolean=true;
  isFullScreen: boolean;
  initialize: boolean;
  public userName;
  public hotelName;
  public pageId;
  public notificationCount: number;
  public numberOfTasks: number = 0;
  public numberOfNoAck: number = 0;
  public sideBarOpened: boolean = false;
  public showHotelSelection: boolean = false;
  public topbarBackgroundVisible: boolean = true;
 
  public avatar: string = "assets/images/avatar.png";
  public logo: string = "assets/images/exx_inf.png";
  userInf: UserInfo;
  public profileDisplay: boolean = false;
  topbarVisible: boolean = true;
  enableVibration: boolean;
  serverSideProcessing: boolean;
  notificationEnabled : boolean;
  messages : SystemMessageDto[]=[];
  // subsciptions
  userNameChanged$: Subscription;
  formIdChanged$: Subscription;
  toggleSideBar$: Subscription;
  topBarVisibilityChanged$: Subscription;
  repairRabbiToFro$: Subscription;
  loadDailyTaskCount$: Subscription;
  topBarBackgroundVisibilityChanged$: Subscription;
  connectionEstablished$: Subscription;
  loadNotificationsByStateCode$: Subscription;
  updateNotificaton$: Subscription;
  reloadNotifications$: Subscription;
  changeToken$: Subscription;
  subscribeForPush$: Subscription;
  unsubscribeFromPush$: Subscription;
  getActiveMessages$ : Subscription;
  pushSettingsVisible: boolean;
  constructor(
    public topBarManagerService: TopBarManagerService,
    private authService: AuthService,
    private userService: UserService,
    public sessionStorageService: SessionStorageService,
    private rabbitLogService: RabbitLogService,
    private translate: TranslateService,
    private dailyTaskService: DailyTaskService,
    private hwAlertService : HwAlertService,
    private confirmService: HwConfirmationDialogService,
    private loginService : LoginService,
    private systemMessagesService : SystemMessagesViewerService,
    private messagesDialog : MatDialog,
    private pwaService : PwaService 
  ) {
    // console.log( dayjs().format('YYYY.MM.DD HH:mm:ss') +  '- topbar.component created' ) 
   
   }

  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
    // this.notificationEnabled = false;
    if(localStorage.getItem('psh') === 'true') {
      this.notificationEnabled = true;
     
    } else {
      this.notificationEnabled = false;
     
    }

    this.pushSettingsVisible = this.sessionStorageService.getPushSettingsVisible();
    this.updateDailyTaskCount();
    this.topBarBackgroundVisibilityChanged$ = this.topBarManagerService.topbarBackgroundVisibilityChanged.subscribe(evt => {
      this.topbarBackgroundVisible = evt;
    })
    this.topBarVisibilityChanged$ = this.topBarManagerService.topBarVisibilityChanged.subscribe(visible => {
      this.topbarVisible = visible;
    })
    this.userNameChanged$ = this.topBarManagerService.userNameChanged.subscribe(evt => {
      this.userName = evt;
    });

    this.toggleSideBar$ = this.topBarManagerService.toggleSideBar.subscribe(evt => {
      this.sideBarOpened = evt;
    })

    this.topBarManagerService.numberOfTasksChanged.subscribe(event => {
      this.numberOfTasks = event;
    });

    this.topBarManagerService.noAckChanged.subscribe(event => {
      this.numberOfNoAck = event as number;
    })

    this.topBarManagerService.LoggedIn.subscribe( result=> {
      this.loggedIn = result;
    })
    this.formIdChanged$ = this.topBarManagerService.formIdChanged.subscribe(evt => {
      this.pageId = evt;
    })

    this.topBarManagerService.EnableVibration.subscribe((event: boolean) => {
      this.enableVibration = event;
    })
    // ?????????????????

    this.topBarManagerService.setNoAck();
    this.getActiveMessages$=this.systemMessagesService.GetActiveMessages('HOK').subscribe(result=> {
      const res = result as SystemMessageDto[];
      if( res["Result"].length > 0) {
        this.messages = res["Result"];
      }
    })
   
    this.loginService.LoginProcessFinished.subscribe(()=> {
      this.pushSettingsVisible = this.sessionStorageService.getPushSettingsVisible();
      navigator.serviceWorker.getRegistration('assets/sw.js').then( registration=> {
        if(localStorage.getItem('psh') === 'true') {
          this.notificationEnabled = true;
          registration.active.postMessage({type: 'enablepush', payload: {}})
        } else {
          this.notificationEnabled = false;
          registration.active.postMessage({type: 'disablepush', payload: {}})
        }
      })
      this.notificationEnabled = localStorage.getItem("psh") ==="true" ?true : false;
    })
  }
  ngOnDestroy(): void {
    this.repairRabbiToFro$?.unsubscribe();
    this.userNameChanged$?.unsubscribe();
    this.formIdChanged$?.unsubscribe();
    this.toggleSideBar$?.unsubscribe();
    this.topBarVisibilityChanged$?.unsubscribe();
    this.repairRabbiToFro$?.unsubscribe();
    this.loadDailyTaskCount$?.unsubscribe();
    this.topBarBackgroundVisibilityChanged$?.unsubscribe();
    this.getActiveMessages$?.unsubscribe();
  }

  openMenu() {
    this.toggleMenu.emit();
  }
  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  toggleFullScreen() {
    if (this.isFullScreen) {
      this.closefullscreen();
    }
    else {
      this.openfullscreen();
    }
  }
  repairRabbiToFro() {
   this.repairRabbiToFro$ = this.rabbitLogService.repairRabbitToFro(this.sessionStorageService.getSelectedUnit(),
      this.sessionStorageService.getSelectedHotelId()).subscribe(result => {
        const res = result as RequestResult;
        let message = {}
        if (res.Code == 0) {
          this.hwAlertService.info(this.translate.instant("common.fro_connection_restored",new  Alert({ autoClose:true, fade:true, position: ToastPosition.TopRight})))
        }
        else {
          this.hwAlertService.error(this.translate.instant("common.fro_error_restoring_connection",new  Alert({ autoClose:false, fade:true, position: ToastPosition.TopRight})))
        }
      })
  }
 
  toggleNotification() {
    const options: HwConfirmationDialogData = {
      cancelButtonCaption: this.translate.instant("common.cancel"),
      message: this.translate.instant("common.wanna_turn_off_notifications"),
      title: this.translate.instant("common.message"),
      okButtonCaption: this.translate.instant("common.yes")
    }
    if( this.notificationEnabled) {
      this.confirmService.open(options);
      this.confirmService.confirmed().subscribe(confirmed => {
        if( confirmed) {
          this.notificationEnabled = !this.notificationEnabled
          localStorage.setItem("psh",this.notificationEnabled.toString());
          this.sendMessageToWorker(this.notificationEnabled);
        }
  
      })
    } else {
      this.notificationEnabled = !this.notificationEnabled
      localStorage.setItem("psh",this.notificationEnabled.toString())
      this.sendMessageToWorker(this.notificationEnabled);
    }


   

    //localStorage.setItem("psh",this.notificationEnabled.toString())
  }

  sendMessageToWorker(notifEnabled : boolean) {
    navigator.serviceWorker.getRegistration('assets/sw.js').then( registration=> {
      localStorage.setItem("psh",notifEnabled.toString())
      if(notifEnabled) {
        registration.active.postMessage({type: 'enablepush', payload: {}})
      } else {
        registration.active.postMessage({type: 'disablepush', payload: {}})
      }
    })
  }
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  updateDailyTaskCount() {
    if( this.sessionStorageService.getSelectedUnit()) {
      this.loadDailyTaskCount$ = this.dailyTaskService.getNumberOfTasks(this.sessionStorageService.getSelectedUnit(),
      this.sessionStorageService.getSelectedHotelId(),
      this.authService.getEmailFromToken())
      .subscribe(evt => {
        const res = evt as RequestResult;
        this.numberOfTasks = res.Data as number;
      })

    }
  }


  openfullscreen() {
    // Trigger fullscreen
    this.isFullScreen = true;
    const docElmWithBrowsersFullScreenFunctions = document.documentElement as HTMLElement & {
      mozRequestFullScreen(): Promise<void>;
      webkitRequestFullscreen(): Promise<void>;
      msRequestFullscreen(): Promise<void>;
    };

    if (docElmWithBrowsersFullScreenFunctions.requestFullscreen) {
      docElmWithBrowsersFullScreenFunctions.requestFullscreen();
    } else if (docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen) { /* Firefox */
      docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen();
    } else if (docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen();
    } else if (docElmWithBrowsersFullScreenFunctions.msRequestFullscreen) { /* IE/Edge */
      docElmWithBrowsersFullScreenFunctions.msRequestFullscreen();
    }

  }

  closefullscreen() {
    this.isFullScreen = false;
    const docWithBrowsersExitFunctions = document as Document & {
      mozCancelFullScreen(): Promise<void>;
      webkitExitFullscreen(): Promise<void>;
      msExitFullscreen(): Promise<void>;
    };
    if (docWithBrowsersExitFunctions.exitFullscreen) {
      docWithBrowsersExitFunctions.exitFullscreen();
    } else if (docWithBrowsersExitFunctions.mozCancelFullScreen) { /* Firefox */
      docWithBrowsersExitFunctions.mozCancelFullScreen();
    } else if (docWithBrowsersExitFunctions.webkitExitFullscreen) { /* Chrome, Safari and Opera */
      docWithBrowsersExitFunctions.webkitExitFullscreen();
    } else if (docWithBrowsersExitFunctions.msExitFullscreen) { /* IE/Edge */
      docWithBrowsersExitFunctions.msExitFullscreen();
    }

  }

  logout() {
    this.sessionStorageService.clear();
    this.topBarManagerService.resetNumberOfTasks();
    this.authService.gotoLogin();
    this.topBarManagerService.setTopbarVisibility(false);
    
  }

  notificationIconClass(enabled: boolean) {
    return enabled ? "mdi mdi-bell" : "mdi mdi-bell-off"
  }

  openMessages() {
    //  this.systemMessagesService.openDialog();
      const dialogRef = this.messagesDialog.open(SystemMessagesViewerComponent, {
        disableClose: true,
        hasBackdrop: true,
        width: "85vw",
        data: "HOK," + this.sessionStorageService.getUserLanguage()
      })
    
    }
    hasMessage() : boolean {
      return this.messages.length > 0 ? true : false
    }

    addToHomeScreen() {
      this.pwaService.addToHomeScreen.emit();
    }
  //#endregion =================================================================================================================



}
