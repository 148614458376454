// Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

// 3rdparty
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
// Hostware
import { CleaningtypeRoomstateService } from '@app/kip/services/cleaningtype-roomstate.service';
import { Alert, HwAlertService, ToastPosition } from '@globalshared/hw-alert-component/src/lib';
import { TopBarManagerService } from '@app/core/services/topbar-manager.service';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { HwConfirmationDialogService } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.service';
import { vwTransCleaning } from '@app/kip/dto/CleaningTypeRoomState';
import { CleaningType } from '@app/kip/dto/CleaningType';
import { SelectItem } from '@app/core/classes/SelectItem';
import { RequestResult } from '@app/core/dto/RequestResult';
import { ReactiveFormsHelperService } from '@app/core/services/reactive-forms-helper.service';
import { HotelMode } from '@app/kip/enums/HotelMode';



@AutoUnsubscribe()
@Component({
  selector: 'cleaning-type-roomstate-editor',
  templateUrl: './cleaning-type-roomstate-editor.component.html',
  styleUrls: ['./cleaning-type-roomstate-editor.component.scss']
})
export class CleaningTypeRoomstateEditorComponent implements OnInit, OnDestroy {
  loaded: boolean;

  loadCleaningtypeRoomstates$: Subscription;
  saveCleanintypeRoomStates$: Subscription;
  hotelUnitChanged$: Subscription;

  editedItem: vwTransCleaning;
  editorForm: FormGroup;
  cleaningTypes: SelectItem[] = [];
  constructor(
    private cleaningTypeRoomstateService: CleaningtypeRoomstateService,
    private hwAlertService: HwAlertService,
    public topBarManagerService: TopBarManagerService,
    private sessionStorageService: SessionStorageService,
    private confirmService: HwConfirmationDialogService,
    private translate: TranslateService,
    private rfHelperService: ReactiveFormsHelperService,

    private fb: FormBuilder,
  ) {

    this.editedItem = null;
    this.editorForm = fb.group({
      'DayUse': new FormControl(''),
      'DepToArr': new FormControl(''),
      'DepToEmpty': new FormControl(''),
      'DepToOoo': new FormControl(''),
      'EmptyToArr': new FormControl(''),
      'EmptyToOoo': new FormControl(''),
      'Occupied': new FormControl(''),
      'OccupiedBed': new FormControl(''),
      'OooToArr': new FormControl(''),
      'OooToEmpty': new FormControl(''),
      'OooToOoo': new FormControl('')
    })
  }


  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
    this.topBarManagerService.HideRootHotel.emit();
    this.hotelUnitChanged$ = this.topBarManagerService.hotelUnitChanged.subscribe(event => {
      this.loadAll();
    })
    this.loadAll();
  }
  ngOnDestroy(): void {
    this.loadCleaningtypeRoomstates$?.unsubscribe();
    this.saveCleanintypeRoomStates$?.unsubscribe();
    this.hotelUnitChanged$?.unsubscribe();
  }

  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================

  loadAll() {
    this.loadCleaningtypeRoomstates$ = this.cleaningTypeRoomstateService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId())
      .subscribe(result => {
        var res = result as RequestResult;
        if (res) {

          this.cleaningTypes = res.Data.Cleanings as SelectItem[];
          this.cleaningTypes.forEach(item => {
            item.label = item.value + " - " + item.label;
          });
          this.cleaningTypes.unshift({ value: "", label: this.translate.instant("common.not_set") })
          this.editedItem = res.Data as vwTransCleaning;
          this.loaded = true;
          this.rfHelperService.fillFormFromRecord(this.editedItem, this.editorForm);

        }
      })
  }
  saveAll() {
    this.rfHelperService.updateRecordFromForm(this.editedItem, this.editorForm);
    if (this.editedItem.DayUse == '' && this.editedItem.DepToArr == '' && this.editedItem.DepToEmpty == '' &&
      this.editedItem.DepToOoo == '' && this.editedItem.EmptyToArr == '' && this.editedItem.EmptyToOoo == '' &&
      this.editedItem.Occupied == '' && this.editedItem.OccupiedBed == '' && this.editedItem.OooToArr == '' &&
      this.editedItem.OooToEmpty == '' && this.editedItem.OooToOoo) {
      this.sessionStorageService.HotelMode = HotelMode.ROOMSTATE_BASED
    } else {
      this.sessionStorageService.HotelMode = HotelMode.TASK_BASED
    }

    this.saveCleanintypeRoomStates$ = this.cleaningTypeRoomstateService.Save(this.editedItem).subscribe(result => {

      var res = result as RequestResult;
      if (res.Code == 0) {
        this.hwAlertService.info(this.translate.instant("common.saved_successfully"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
      }
      else {
        this.hwAlertService.error(this.translate.instant("common.error_while_performing_operation"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));

      }
    })
  }
  //#endregion =================================================================================================================




}
