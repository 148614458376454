

// Angular
import { Component, OnDestroy, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, first, firstValueFrom, Subject, Subscription, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

// 3rdparty
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import dayjs from 'dayjs';
import { MatDialog } from '@angular/material/dialog';
import { saveFile, saveAs } from 'file-saver';

// Hostware
import { AuthService } from '@app/core/services/auth.service';
import { KipUserService } from '@app/kip/services/kip-user.service';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { TopBarManagerService } from '@app/core/services/topbar-manager.service';
import { DailyTaskService } from '@app/kip/services/daily-task.service';
import { vwTaskListItem } from '@app/kip/viewdto/vwCleaningState/vwTaskListItem';
import { VwTaskDiffListDto } from '@app/kip/viewdto/vwTaskDiff/vwTaskDiffListDto';
import { vwTaskListFilter } from '@app/kip/viewdto/vwTaskListFilter';
import { CameraManagerService } from '@app/kip/services/camera-manager.service';
import { ImageManagerService } from '@app/kip/services/image-manager.service';
import { FormStateService } from '@app/kip/services/form-state.service';
import { RequestResult } from '@app/core/dto/RequestResult';
import { HokParamsService } from '@app/kip/services/hok-params.service';
import { TaskListFilterComponent, TaskListFilterConditions } from '@app/kip/components/task-list-filter/task-list-filter.component';
import { TaskDiffListWrapper } from '@app/kip/viewdto/vwTaskDiff/task-diff-list-wrapper';
import { vwCleaningState } from '@app/kip/viewdto/vwCleaningState/vwCleaningState';
import { RoleType } from '@app/shared/enums/roles';
import { RoleService } from '@app/shared/services/role.service';
import { TaskStates } from '@app/kip/enums/TaskStates';
import { RoomState } from '@app/shared/enums/roomstates';
import { environment } from '@environments/environment';
import { TaskDifferenceParam } from '@app/kip/viewdto/vwTaskDiff/task-difference-param';
import { CrudMessagesService } from '@app/kip/services/crud-messages.service';
import { HwConfirmationDialogService } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.service';
import { HwConfirmationDialogData } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.component';
import { TaskStatus } from '@app/kip/viewdto/taskStatus';

import { Alert, HwAlertService, ToastPosition } from '@globalshared/hw-alert-component/src/lib';
import { FormOperationMode } from '@app/core/enums/form-operation-mode';
import { AddTaskQueryParam } from '@app/kip/classes/AddTaskParam';
import { CleaningType } from '@app/kip/dto/CleaningType';
import { vwTaskReadinessItem } from '@app/kip/viewdto/vwTaskReadinessItem';

import { vwTimeLine } from '@app/kip/viewdto/vwdailytask/vwTimeLine';
import { KipUser } from '@app/kip/dto/KipUser';
import { CleaningTypeService } from '@app/kip/services/cleaning-type.service';
import { AddTaskDialogComponent } from '@app/kip/dialogs/add-task-dialog/add-task-dialog.component';

import { TaskHistoryDialogComponent } from '@app/kip/dialogs/task-history-dialog/task-history-dialog.component';
import { vwCleaningType } from '@app/kip/viewdto/vwdailytask/vwCleaningType';
import { vwMaintenanceType } from '@app/kip/viewdto/vwdailytask/vwMaintenanceType';
import { PhotoDialogComponent } from '@app/kip/dialogs/photo-dialog/photo-dialog.component';
import { SpinnerService } from '@app/core/services/spinner.service';
import { PhotoGalleriaDialogComponent } from '@app/kip/dialogs/photo-galleria-dialog/photo-galleria-dialog.component';
import { TaskListOrder } from '@app/kip/enums/TasklistOrder';
@AutoUnsubscribe()
@Component({
  selector: 'task-list-editor',
  templateUrl: './task-list-editor.component.html',
  styleUrls: ['./task-list-editor.component.scss']
})
export class TaskListEditorComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild("filter") taskListFilter: TaskListFilterComponent;


  isOpen: boolean = false;
  scrollPosition: number = 0;
  froDatum: string;
  fromUrl: string;
  maintenancesVisible = true;
  cleaningsVisible = true;
  mustOpenTheCard: boolean = false;
  cameraPanelVisible: boolean;
  cleaningList: vwTaskListItem[] = [];
  allCleaningList: vwTaskListItem[] = [];
  maintenanceList: vwTaskListItem[] = [];
  allMaintenanceList: vwTaskListItem[] = [];
  taskDiffs: VwTaskDiffListDto[] = [];
  imageNames: string[] = [];
  kipUsers: KipUser[] = [];
  supervisors: KipUser[] = [];
  selectedTaskItem: vwTaskListItem = new vwTaskListItem();

  hotelUnitChanged$: Subscription;
  loadFroDate$: Subscription;
  getTaskListPdf$: Subscription;
  getTaskListExcel$: Subscription;
  queryParams$: Subscription;
  updateTaskDiff$: Subscription;
  updateTaskStatus$: Subscription;
  loadCleaningTypeByTypeCode$: Subscription;
  updateTaskReadiness$: Subscription;
  setDontDisturb$: Subscription
  exportByResponsibility$: Subscription;
  getMaintenanceListPdf$: Subscription;



  selectedCleaningType: vwTaskListItem;
  selectedMaintenanceType: vwTaskListItem;
  selectedImages: PhotoItem[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();

  processing: boolean = false;


  constructor(
    private sessionStorageService: SessionStorageService,
    private topBarManagerService: TopBarManagerService,
    private authService: AuthService,
    private cameraManagerService: CameraManagerService,
    private imageManagerService: ImageManagerService,
    private formStateService: FormStateService,
    private hokParamsService: HokParamsService,
    private dailyTaskService: DailyTaskService,
    private activatedRoute: ActivatedRoute,
    public roleService: RoleService,
    private translate: TranslateService,
    private crudMessageService: CrudMessagesService,
    private confirmService: HwConfirmationDialogService,
    private hwAlertService: HwAlertService,
    private kipUserService: KipUserService,
    private cleaningTypeService: CleaningTypeService,
    private addTaskDialog: MatDialog,
    private taskHistoryDialog: MatDialog,
    private photoDialog: MatDialog,
    private photoGaleryDialog: MatDialog,
    private spinnerService: SpinnerService

  ) { }


  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
    this.topBarManagerService.HideRootHotel.emit();
    this.queryParams$ = this.activatedRoute.queryParams.subscribe((params) => {
      if (params.fromUrl) {
        this.fromUrl = params.fromUrl;
      }

    });
    this.hotelUnitChanged$ = this.topBarManagerService.hotelUnitChanged.subscribe(event => {
      this.init();
    })
    this.init();

  }
  ngOnDestroy(): void {
    this.hotelUnitChanged$?.unsubscribe();
    this.loadFroDate$?.unsubscribe();
    this.getTaskListPdf$?.unsubscribe();
    this.getTaskListExcel$?.unsubscribe();
    this.updateTaskDiff$?.unsubscribe();
    this.updateTaskStatus$?.unsubscribe();
    this.loadCleaningTypeByTypeCode$?.unsubscribe()
    this.updateTaskReadiness$?.unsubscribe();
    this.setDontDisturb$?.unsubscribe();
    this.updateTaskDiff$?.unsubscribe();
    this.exportByResponsibility$?.unsubscribe();
    this.getMaintenanceListPdf$?.unsubscribe();

    this.destroy$.next(true);
  }

  ngAfterViewInit() {
    // if(this.formStateService.TaskListFilterInitialised && this.roleService.isInLeaderRole ) {
    //   this.taskListFilter.setMaintanenceGroupId( this.formStateService.getVwTaskListFilter().GroupId )

    // }
    this.taskListFilter.filterChanged();
    // this.applyFilter();
  }

  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================

  selectCleaning(item: vwTaskListItem, mustOpen: boolean) {
    if (this.selectedCleaningType == item) {
      this.selectedCleaningType = null;
    }
    else {
      this.selectedCleaningType = item;
      this.selectedMaintenanceType = null;
      //check photos ###########
      this.selectedImages = [];
      this.imageManagerService.loadImages(item.Tasks[0].DailyTaskId).subscribe(result => {
        this.imageNames = result as Array<string>;
        this.imageNames.forEach(item => {
          let photo: PhotoItem = new PhotoItem();
          photo.alt = "";
          photo.title = "";
          photo.previewImageSrc = environment.photoBaseUrl + item;
          photo.thumbnailImageSrc = environment.photoBaseUrl + item;
          this.selectedImages.push(photo)
          //console.log(this.selectedCleaningImages);
        })
        this.mustOpenTheCard = true;
      })
      this.isOpen = true;
    }

  }

  selectMaintenance(item: vwTaskListItem, mustOpen: boolean) {
    if (this.selectedMaintenanceType == item) {
      this.selectedMaintenanceType = null;
    }
    else {
      this.selectedMaintenanceType = item;
      this.selectedCleaningType = null;
      this.selectedImages = [];
      this.imageManagerService.loadImages(item.Tasks[0].DailyTaskId).subscribe(result => {
        const imageNames = result as Array<string>;
        imageNames.forEach(item => {
          let photo: PhotoItem = new PhotoItem();
          photo.alt = "";
          photo.title = "";
          photo.previewImageSrc = environment.photoBaseUrl + item;
          photo.thumbnailImageSrc = environment.photoBaseUrl + item;
          this.selectedImages.push(photo)
          //console.log(this.selectedCleaningImages);
        })
        this.mustOpenTheCard = true;
      })
    }

  }

  roomStateCleaningPanelStyle(cleaningType: vwTaskListItem) {
    let styles = {};
    if (this.selectedCleaningType === null || this.selectedCleaningType === undefined) {
      styles["box-shadow"] = "0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26) !important";
      return styles;
    }
    if (cleaningType.Tasks[0].CleaningTypesId == this.selectedCleaningType.Tasks[0].CleaningTypesId && this.mustOpenTheCard) {
      styles["box-shadow"] = "0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26) !important";

    } else {
      styles["box-shadow"] = "0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26) !important";

    }
    return styles;
  }

  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  showAddTaskDialog() {
    let addTaskParam: AddTaskQueryParam = new AddTaskQueryParam();
    addTaskParam.returnUrl = "";
    addTaskParam.mode = FormOperationMode.OperationModeAdd;
    addTaskParam.dailyTypeId = -1;
    addTaskParam.placeTypeCode = "R";
    addTaskParam.placeNumber = "-1";
    const dialogRef = this.addTaskDialog.open(AddTaskDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: "60vw",
      panelClass: "add-task-dialog",
      // height: "80vh",
      data: addTaskParam
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const res = result as RequestResult;
        this.crudMessageService.showAddOrEditMessage(res);
        this.loadAll();
      }


    })


  }

  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  filterChanged(filt: TaskListFilterConditions) {
    var tmpArrRooms: vwTaskListItem[] = [];
    var mustInclude: boolean;
    if (filt.fItemType == "-1" || filt.fItemType == "0") {
      this.allCleaningList.forEach(item => {
        mustInclude = false;

        if (filt.fTaskStateCreated) {
          if (item.Tasks[0].TaskState == TaskStates.Created) {
            mustInclude = true;
          }
        }
        if (filt.fTaskStatePaused) {
          if (item.Tasks[0].TaskState == TaskStates.Paused) {
            mustInclude = true;
          }
        }
        if (filt.fTaskStateRunning) {
          if (item.Tasks[0].TaskState == TaskStates.Running) {
            mustInclude = true;
          }
        }
        if (filt.fTaskStateEnded) {
          if (item.Tasks[0].TaskState == TaskStates.Ended) {
            mustInclude = true;
          }
        }
        if (filt.fTaskStateDeleted) {
          if (item.Tasks[0].TaskState == TaskStates.Deleted) {
            mustInclude = true;

          }
        }

        if (filt.fTaskStateAccepted) {
          if (item.Tasks[0].TaskState == TaskStates.Accepted) {
            mustInclude = true;

          }
        }
        // RoomNumber

        if (item.TaskType == "R") {
          if (!item.Room.RoomNumber.toLowerCase().startsWith(filt.fRoomNumber.toLowerCase()) &&
            filt.fRoomNumber !== "" && mustInclude) {
            mustInclude = false;
          }

          // Floor
          if (!item.Room.Floor.toString().toLowerCase().startsWith(filt.fFloor.toString().toLowerCase()) &&
            filt.fFloor.toString() !== "" && mustInclude) {
            mustInclude = false;
          }
        }
        if (item.TaskType == "P") {
          if (!item.PublicArea.AreaCode.toLowerCase().startsWith(filt.fRoomNumber.toLowerCase()) &&
            filt.fRoomNumber !== "" && mustInclude) {
            mustInclude = false;
          }
        }

        if (filt.fMyTasksOnly && this.roleService.userIsInRole(RoleType.GONDNOKNO) && mustInclude) {
          if ((!(item.InspectorId == this.authService.getUserIdFromToken()
            || item.AssignedToId == this.authService.getUserIdFromToken())
            || (item.InspectorId == null))) {
            mustInclude = false;
          }
        }

        if (filt.fSupervisor !== "-1" && mustInclude) {
          if (item.InspectorId !== parseInt(filt.fSupervisor)) {
            mustInclude = false;
          }
        }

        if (filt.fMyTasksOnly && (this.roleService.userIsInRole(RoleType.TAKARITO) || this.roleService.userIsInRole(RoleType.MUSZAKI_VEZETO)) && mustInclude) {
          if ((item.AssignedToId !== this.authService.getUserIdFromToken()) || (item.AssignedToId == null)) {
            mustInclude = false;
          }
        }
        if (this.roleService.userIsInRole(RoleType.RECEPCIOS) && mustInclude) {
          if (filt.fMyTasksOnly) {
            mustInclude = false;
          }
        }

        if (filt.fAssignedTo !== "-1" && mustInclude) {
          if (filt.fAssignedTo !== item.AssignedToId?.toString()) {
            mustInclude = false;
          }
        }

        if (this.cleaningsVisible && mustInclude) {
          if (parseInt(filt.fCleaningGroup) !== 0) {
            if (parseInt(filt.fCleaningGroup) !== item.GroupId) {
              mustInclude = false;
            } else {
              if (parseInt(filt.fCleaningType) !== 0 && parseInt(filt.fCleaningType) !== item.TypeId) {
                mustInclude = false;
              }
            }
          }
        }
        if (mustInclude && ((this.roleService.userIsInRole(RoleType.MUSZAKI_VEZETO) || this.roleService.userIsInRole(RoleType.MUSZAKOS)
          || this.roleService.userIsInRole(RoleType.GONDNOKNO) || this.roleService.userIsInRole(RoleType.TAKARITO) || this.roleService.userIsInRole(RoleType.RECEPCIOS)))) {
          tmpArrRooms.push(item);
        }
      });
    }

    this.cleaningList = JSON.parse(JSON.stringify(tmpArrRooms)) as vwTaskListItem[];
    const collator = new Intl.Collator('hu');
    switch (filt.fOrder) {
      case TaskListOrder.AssignedTo:
        this.cleaningList.sort((a, b) => collator.compare(a.AssignedToRealName, b.AssignedToRealName))
        break;
      case TaskListOrder.Supervisor:
        this.cleaningList.sort((a, b) => collator.compare(a.InspectorRealName, b.InspectorRealName))
        break;
      case TaskListOrder.RoomNumber:
        this.cleaningList.sort(this.compareByRoomNumber)
        break;
      case TaskListOrder.TaskTypeName:
        this.cleaningList.sort((a, b) => collator.compare(a.TypeName, b.TypeName))
        break;
      case TaskListOrder.TaskDate:
      case TaskListOrder.None:
        this.cleaningList.sort(this.compareByTaskDate)
        break;

    }

    tmpArrRooms = [];

    if (filt.fItemType == "-1" || filt.fItemType == "1") {
      this.allMaintenanceList.forEach(item => {
        mustInclude = false;

        if (filt.fTaskStateCreated) {
          if (item.Tasks[0].TaskState == TaskStates.Created) {
            mustInclude = true
          }
        }
        if (filt.fTaskStatePaused) {
          if (item.Tasks[0].TaskState == TaskStates.Paused) {
            mustInclude = true
          }
        }
        if (filt.fTaskStateRunning) {
          if (item.Tasks[0].TaskState == TaskStates.Running) {
            mustInclude = true
          }
        }
        if (filt.fTaskStateEnded) {
          if (item.Tasks[0].TaskState == TaskStates.Ended) {
            mustInclude = true
          }
        }
        if (filt.fTaskStateDeleted) {
          if (item.Tasks[0].TaskState == TaskStates.Deleted) {
            mustInclude = true
          }
        }
        if (filt.fTaskStateAccepted) {
          if (item.Tasks[0].TaskState == TaskStates.Accepted) {
            mustInclude = true
          }
        }


        //RoomNumber
        if (item.TaskType == "R") {
          if (!item.Room.RoomNumber.toLowerCase().startsWith(filt.fRoomNumber) &&
            filt.fRoomNumber !== "" && mustInclude) {
            mustInclude = false;
          }
          // Floor
          if (!item.Room.Floor.toString().toLowerCase().startsWith(filt.fFloor.toString().toLowerCase()) &&
            filt.fFloor.toString() !== "" && mustInclude) {
            mustInclude = false;
          }
        }


        if (filt.fMyTasksOnly && (this.roleService.userIsInRole(RoleType.MUSZAKI_VEZETO) || this.roleService.userIsInRole(RoleType.GONDNOKNO)) && mustInclude) {
          if ((!(item.InspectorId == this.authService.getUserIdFromToken()
            || item.AssignedToId == this.authService.getUserIdFromToken())
            || (item.InspectorId == null))) {
            mustInclude = false;
          }
        }
        if (filt.fMyTasksOnly && this.roleService.userIsInRole(RoleType.MUSZAKOS) && mustInclude) {
          if ((item.AssignedToId !== this.authService.getUserIdFromToken()) || (item.AssignedToId == null)) {
            mustInclude = false;
          }
        }

        // if (this.roleService.userIsInRole(RoleType.TAKARITO)) {
        //   mustInclude = false;
        // }

        // if (this.roleService.userIsInRole(RoleType.GONDNOKNO) && mustInclude && filt.fMyTasksOnly) {
        //   mustInclude = false;
        // }

        if (filt.fAssignedTo !== "-1" && mustInclude) {
          if (parseInt(filt.fAssignedTo) !== item.AssignedToId) {
            mustInclude = false;
          }
        }
        if (filt.fSupervisor !== "-1" && mustInclude) {
          if (item.InspectorId !== parseInt(filt.fSupervisor)) {
            mustInclude = false;
          }
        }

        if (this.maintenancesVisible && mustInclude) {
          if (parseInt(filt.fMaintenanceGroup) !== 0) {
            if (parseInt(filt.fMaintenanceGroup) !== item.GroupId) {
              mustInclude = false;
            } else {
              if (parseInt(filt.fMaintenanceType) !== 0 && parseInt(filt.fMaintenanceType) !== item.TypeId) {
                mustInclude = false;
              }
            }
          }
        }

        if (mustInclude && (filt.fItemType == "-1" || filt.fItemType == "1")) {
          tmpArrRooms.push(item);
        }
      })
    }

    this.maintenanceList = JSON.parse(JSON.stringify(tmpArrRooms)) as vwTaskListItem[];

    switch (filt.fOrder) {
      case TaskListOrder.AssignedTo:
        this.cleaningList.sort((a, b) => collator.compare(a.AssignedToRealName, b.AssignedToRealName))
        break;
      case TaskListOrder.Supervisor:
        this.cleaningList.sort((a, b) => collator.compare(a.InspectorRealName, b.InspectorRealName))
        break;
      case TaskListOrder.RoomNumber:
        this.cleaningList.sort(this.compareByRoomNumber)
        break;
      case TaskListOrder.TaskTypeName:
        this.cleaningList.sort((a, b) => collator.compare(a.TypeName, b.TypeName))
        break;
      case TaskListOrder.TaskDate:
      case TaskListOrder.None:
        this.cleaningList.sort(this.compareByTaskDate)
        break;

    }
  }

  init() {
    this.loadFroDate$ = this.hokParamsService.loadFroDatum(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()).subscribe(event => {
      const hokpResult = event as RequestResult;
      this.froDatum = hokpResult.Data;
      this.loadAll();
    })
    this.loadAll();
  }

  loadAll() {
    let units: string;
    let hotels: string;
    this.taskDiffs = [];
    if (this.sessionStorageService.multiHotel) {
      if (!this.sessionStorageService.IgnoreMultiHotel) {
        const sel: string[] = this.sessionStorageService.getFlattenedHotels()[0].value.toString().split("@")
        units = sel[0] + ',' + this.sessionStorageService.getSelectedUnit();
        hotels = sel[1] + ',' + this.sessionStorageService.getSelectedHotelId().toString()
      } else {
        units = this.sessionStorageService.getSelectedUnit();
        hotels = this.sessionStorageService.getSelectedHotelId().toString();
      }
    } else { // nem multihotel, csak a kiválasztott
      units = this.sessionStorageService.getSelectedUnit();
      hotels = this.sessionStorageService.getSelectedHotelId().toString();
    }
    this.spinnerService.setSpinnerVisibility(true);
    combineLatest({
      taskStates: this.dailyTaskService.loadTaskStates(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId(), -1),
      diffs: this.dailyTaskService.loadTaskDifferences(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()),
      kipusers: this.kipUserService.loadAllMulti(units, hotels),

    }).pipe(takeUntil(this.destroy$)).subscribe(result => {
      this.taskDiffs = (((result.diffs as RequestResult).Data) as TaskDiffListWrapper).Rooms;
      const cleaningState = (result.taskStates as RequestResult).Data as vwCleaningState;
      this.cleaningList = cleaningState.VwCleaningList;
      this.allCleaningList = cleaningState.VwCleaningList;
      this.maintenanceList = cleaningState.VwMaintenanceList;
      this.allMaintenanceList = cleaningState.VwMaintenanceList;
      this.kipUsers = (result.kipusers as RequestResult).Data as Array<KipUser>
      const allUsers = { UserId: -1, UserName: this.translate.instant("common.all"), Subscriber: '', HotelId: 0, UserCode: '', UserUuId: '', Data: '', RealName: this.translate.instant("common.all"), Active: true }
      this.kipUsers.splice(0, 0, allUsers);
      this.kipUsers.forEach(kipUser => {
        if (this.roleService.isUserInRole(kipUser.UserName, RoleType.GONDNOKNO) ||
          this.roleService.isUserInRole(kipUser.UserName, RoleType.MUSZAKI_VEZETO)) {
          this.supervisors.push(JSON.parse(JSON.stringify(kipUser)));
        }
      })
      this.supervisors.splice(0, 0, allUsers);
      this.supervisors = JSON.parse(JSON.stringify(this.supervisors as Array<KipUser>));
      this.spinnerService.setSpinnerVisibility(false)
      this.taskListFilter.filterChanged();
      window.scrollTo(0, this.scrollPosition);
      this.processing = false;
    })
  }

  areaName(cleaningType: vwTaskListItem) {
    if (cleaningType.TaskType == "R") {
      return cleaningType.Room.RoomNumber;
    }
    else {
      return cleaningType.PublicArea.AreaCode;
    }
  }
  /*default style functions */

  activeCleaningItemStyle(item: vwTaskListItem) {
    let styles = {};
    if (this.selectedCleaningType === null || this.selectedCleaningType === undefined) {
      styles["margin-left"] = "10px";
      styles["margin-right"] = "10px"
      styles["margin-top"] = "0px";
      styles["margin-bottom"] = "0px";
      styles["box-shadow"] = "0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26) !important";
      styles["padding"] = "1em";
      return styles;
    }
    if (item.Tasks[0].CleaningTypesId == this.selectedCleaningType.Tasks[0].CleaningTypesId && this.mustOpenTheCard) {
      styles["margin-left"] = "-1em";
      styles["margin-right"] = "-1em"
      styles["margin-top"] = "10px";
      styles["margin-bottom"] = "10px";
      styles["padding"] = "0.5em";
      styles["box-shadow"] = "0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26) !important";

    } else {
      styles["margin-left"] = "10px";
      styles["margin-right"] = "10px"
      styles["margin-top"] = "0px";
      styles["margin-bottom"] = "0px";
      styles["box-shadow"] = "0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26) !important";
      styles["padding"] = "1em"

    }
    return styles;

  }

  activeMaintenanceItemStyle(item: vwTaskListItem) {
    let styles = {};
    if (item == this.selectedMaintenanceType && this.mustOpenTheCard) {
      styles["margin-left"] = "-1em";
      styles["margin-right"] = "-1em"
      styles["margin-top"] = "10px";
      styles["margin-bottom"] = "10px";
      styles["padding"] = "0.5em";
      styles["box-shadow"] = "0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26) !important";

    } else {
      styles["margin-left"] = "10px";
      styles["margin-right"] = "10px"
      styles["margin-top"] = "0px";
      styles["margin-bottom"] = "0px";
      styles["box-shadow"] = "0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26) !important";
      styles["padding"] = "1em"

    }
    return styles;
  }

  cleaningIconClass(taskListItem: vwTaskListItem) {
    if (taskListItem.Tasks[0].Need_Cleaning) {
      return {
        'mdi mdi-broom brush-icon': true
      }
    } else {
      return {
        'mdi mdi-minus-circle-off dnd-icon': true,
        'not-ask-for-cleaning-icon': true,
      }
    }
  }
  roomStateColorStyle(rowData: vwTaskListItem) {
    let styles = {};
    if (rowData.Room.RoomStateCode == RoomState.EMPTY_AVAILABLE || rowData.Room.RoomStateCode == RoomState.RESERVED_OK) {
      styles["background-color"] = "#81c784";
      styles["color"] = "#ccdacd";
      styles["font-weight"] = "500";
    }
    if (rowData.Room.RoomStateCode == RoomState.EMPTY_CLEAN || rowData.Room.RoomStateCode == RoomState.RESERVED_CLEAN) {
      styles["background-color"] = "#03a9f4";
      styles["color"] = "#9de1fe";
      styles["font-weight"] = "500";
    }
    if (rowData.Room.RoomStateCode == RoomState.EMPTY_DIRTY || rowData.Room.RoomStateCode == RoomState.RESERVED_DIRTY) {
      styles["background-color"] = "#f35770";
      styles["color"] = "#ffafbc";
      styles["font-weight"] = "500";
    }

    if (rowData.Room.RoomStateCode == RoomState.OUT_OF_ORDER) {
      styles["background-color"] = "#424242";
      styles["color"] = "#adadad";
      styles["font-weight"] = "500";
    }

    if (rowData.Room.RoomStateCode == RoomState.OUT_OF_SERVICE) {
      styles["background-color"] = "#EBA9E5";
      styles["color"] = "#9750b9";
      styles["font-weight"] = "500";
    }
    if (rowData.Room.RoomStateCode == RoomState.SHOW_ROOM) {
      styles["background-color"] = "#ffca28";
      styles["color"] = "#ffeeba";
      styles["font-weight"] = "500";
    }

    return styles;
  }
  roomStateIcon(rowData: vwTaskListItem) {

    if (rowData.Room.RoomStateCode == RoomState.EMPTY_AVAILABLE) { // Ellenőrzött tiszta  
      return {
        'mdi mdi-check-all': true,
        'room-state-icon': true,
        'task-accepted-icon': true,
      }
    }

    if (rowData.Room.RoomStateCode == RoomState.EMPTY_AVAILABLE || rowData.Room.RoomStateCode == RoomState.RESERVED_OK) {
      return {
        'mdi mdi-check-all': true,
        'room-state-icon': true,
        'task-accepted-icon': true,
      };
    }

    if (rowData.Room.RoomStateCode == RoomState.OUT_OF_ORDER) { // OOO  
      return {
        'mdi mdi-cog': true,
        'room-state-icon': true,
        'ooo-box': true,
      };
    }

    if (rowData.Room.RoomStateCode == RoomState.OUT_OF_SERVICE) { // OOS  
      return {
        'mdi mdi-alert': true,
        'room-state-icon': true,
        'oos-box': true,
      };
    }

    if (rowData.Room.RoomStateCode == RoomState.EMPTY_CLEAN) { // Üres tiszta  
      return {
        'mdi mdi-star': true,
        'room-state-icon': true,
        'clean-box': true,
      }
    }

    if (rowData.Room.RoomStateCode == RoomState.EMPTY_CLEAN || rowData.Room.RoomStateCode == RoomState.RESERVED_CLEAN) {
      return {
        'mdi mdi-star': true,
        'room-state-icon': true,
        'clean-box': true,
      };
    }
    if (rowData.Room.RoomStateCode == RoomState.EMPTY_DIRTY || rowData.Room.RoomStateCode == RoomState.RESERVED_DIRTY) { // piszkos
      return {
        'mdi mdi-delete': true,
        'dirty-box': true,
        'room-state-icon': true
      };
    }
    if (rowData.Room.RoomStateCode == RoomState.SHOW_ROOM) { // Üres tiszta  
      return {
        'mdi mdi-eye': true,
        'room-state-icon': true,
        'show-room-box': true,
      }
    }
    return {
      'room-state-icon': true
    };
  }

  cleaningStatusIcon(rowData: vwTaskListItem) {

    switch (rowData.Tasks[0].TaskState) {
      case TaskStates.Created: {
        return {
          'mdi mdi-checkbox-blank-circle-outline': true,
          'task-created': true,
          'room-state-icon': true
        }
      }
      case TaskStates.Deleted: {
        return {
          'mdi mdi-close-circle-outline': true,
          'task-deleted': true,
          'room-state-icon': true
        }
      }
      case TaskStates.Ended: {
        return {
          'mdi mdi-radiobox-marked': true,
          'task-ended': true,
          'room-state-icon': true
        }
      }
      case TaskStates.Paused: {
        return {
          'mdi mdi-pause-circle-outline': true,
          'task-pause': true,
          'room-state-icon': true
        }
      }
      case TaskStates.Running: {
        return {
          'mdi mdi-play-circle-outline': true,
          'task-play': true,
          'room-state-icon': true
        }
      }
      case TaskStates.Accepted: {
        return {
          'mdi mdi-check-all': true,
          'task-accepted': true,
          'room-state-icon': true
        }
      }
    }
  }

  issueDate(cleaningType: vwTaskListItem) {
    if (cleaningType.TaskType == "R") {
      return "";
    } else {
      return cleaningType.TypeTimeStr;
    }
  }
  commentChunk(item: vwTaskListItem): string {
    let ret = item.Comment;
    if (ret?.length > 25) {
      ret = ret.substring(0, 25) + '...';
    }
    return ret;
  }
  hasPhoto(item: vwTaskListItem) {
    if (this.selectedCleaningType) {
      if (this.selectedCleaningType.Tasks[0].CleaningTypesId == item.Tasks[0].CleaningTypesId && this.selectedImages.length > 0) {
        return true;
      }
      else {
        return false;
      }
    }

    if (this.selectedMaintenanceType) {
      if (this.selectedMaintenanceType.Tasks[0].CleaningTypesId == item.Tasks[0].CleaningTypesId && this.selectedImages.length > 0) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  showPhotos(item: vwTaskListItem) {
    const dialogRef = this.photoGaleryDialog.open(PhotoGalleriaDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: 'full-screen-modal',
      data: item.Tasks[0].DailyTaskId
    })
  }

  getCleaningTasks() {
    let csvString: string = "";
    if (this.selectedCleaningType) {
      if (this.selectedCleaningType.Tasks.length > 0) {
        this.selectedCleaningType.Tasks.forEach(item => {
          csvString += item.TaskName + ", ";
        })
      }
      csvString = csvString.substring(0, csvString.length - 1);
    }
    return csvString;
  }
  getMaintenaceTasks() {
    let csvString: string = "";
    if (this.selectedMaintenanceType) {
      if (this.selectedMaintenanceType.Tasks.length > 0) {
        this.selectedMaintenanceType.Tasks.forEach(item => {
          csvString += item.TaskName + ", ";
        })
      }
      csvString = csvString.substring(0, csvString.length - 1);
    }
    return csvString;
  }

  translatedTaskState(state: string) {
    switch (state) {
      case TaskStates.Accepted:
        return this.translate.instant("common.task_inspected");
      case TaskStates.Created:
        return this.translate.instant("common.task_states_created");
      case TaskStates.Deleted:
        return this.translate.instant("common.task_deleted");
      case TaskStates.Paused:
        return this.translate.instant("common.task_pause");
      case TaskStates.Running:
        return this.translate.instant("common.task_started");
      case TaskStates.Ended:
        return this.translate.instant("common.task_ended");
      case "Modified":
        return this.translate.instant("common.modified");
    }
  }

  /*   tasdiff */
  applyTaskDiff(item: VwTaskDiffListDto) {
    const options: HwConfirmationDialogData = {
      cancelButtonCaption: this.translate.instant("common.cancel"),
      message: this.translate.instant("K200p.apply_suggested_modification"),
      title: this.translate.instant("common.message"),
      okButtonCaption: this.translate.instant("common.ok")
    }
    this.confirmService.open(options)
    this.confirmService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        let newData: TaskDifferenceParam = new TaskDifferenceParam();
        newData.FroDate = this.froDatum;
        newData.NewCleaningTypeId = item.NewCleaning.CleaningTypeId;
        newData.OldCleaningTypeId = item.OldCleaning.CleaningTypeId;
        newData.OldDailyTypeId = item.OldCleaning.DailyTypeId;
        newData.RoomId = item.RoomId;
        newData.RoomNumber = item.Roomnumber;
        let params: TaskDifferenceParam[] = [];
        params.push(newData)
        this.addTaskDiff(params);
      }
    })
  }
  applyAllTaskDiff() {
    const options: HwConfirmationDialogData = {
      cancelButtonCaption: this.translate.instant("common.cancel"),
      message: this.translate.instant("K200p.apply_suggested_modification"),
      title: this.translate.instant("common.message"),
      okButtonCaption: this.translate.instant("common.ok")
    }
    this.confirmService.open(options);
    this.confirmService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        let params: TaskDifferenceParam[] = [];
        this.taskDiffs.forEach(item => {
          let newData: TaskDifferenceParam = new TaskDifferenceParam();
          newData.FroDate = this.froDatum;
          newData.NewCleaningTypeId = item.NewCleaning.CleaningTypeId;
          newData.OldCleaningTypeId = item.OldCleaning.CleaningTypeId;
          newData.OldDailyTypeId = item.OldCleaning.DailyTypeId;
          newData.RoomId = item.RoomId;
          newData.RoomNumber = item.Roomnumber;
          params.push(newData)
        });
        this.addTaskDiff(params);
      }
    })
  }
  async addTaskDiff(params: TaskDifferenceParam[]) {
    this.processing = true;
    const result = await firstValueFrom(this.dailyTaskService.updateTaskDifferences(this.sessionStorageService.getSelectedUnit(),
      this.sessionStorageService.getSelectedHotelId(), params))
    const res = result as RequestResult;
    this.crudMessageService.showAddOrEditMessage(res);
    this.loadAll();

    // old code
    // this.updateTaskDiff$ = this.dailyTaskService.updateTaskDifferences(this.sessionStorageService.getSelectedUnit(),
    //   this.sessionStorageService.getSelectedHotelId(), params).subscribe(result => {
    //     const res = result as RequestResult;
    //     this.crudMessageService.showAddOrEditMessage(res);
    //     this.loadAll();
    //   })
  }


  /*  cleaning actions */
  startCleaning(taskItem: vwTaskListItem) {
    this.scrollPosition = document.documentElement.scrollTop;
    taskItem.Tasks[0].TimeSinceLastChange = 0;
    this.updateTaskStatusByeTypeId(taskItem.Tasks[0].CleaningTypesId, TaskStates.Running);
    //this.loadAll();

  }

  pauseCleaning(taskItem: vwTaskListItem) {
    this.scrollPosition = document.documentElement.scrollTop;
    taskItem.Tasks[0].TimeSinceLastChange = 0;
    this.updateTaskStatusByeTypeId(taskItem.Tasks[0].CleaningTypesId, TaskStates.Paused);
    //this.loadAll();
  }

  endCleaning(taskItem: vwTaskListItem) {
    this.scrollPosition = document.documentElement.scrollTop;
    this.loadCleaningTypeByTypeCode$ = this.cleaningTypeService.loadByTypeCode(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()
      , taskItem.TypeCode).subscribe(result => {
        const res = result as RequestResult;
        const data = res.Data as CleaningType;
        taskItem.Tasks[0].TaskState = TaskStates.Ended;
        taskItem.Tasks[0].TimeSinceLastChange = 0;
        if (data.AutoAccepted) {

          this.updateSelectedItemTaskStatus(TaskStates.Accepted, taskItem);
        } else {

          this.updateSelectedItemTaskStatus(TaskStates.Ended, taskItem);
        }
      })

    //this.loadAll();
    //this.updateTaskStatusByeTypeId(taskItem.Tasks[0].CleaningTypesId, TaskStates.Ended);
  }

  delete(taskItem: vwTaskListItem) {
    this.scrollPosition = document.documentElement.scrollTop;
    taskItem.Tasks[0].TimeSinceLastChange = 0;
    taskItem.Tasks[0].TaskState = TaskStates.Deleted;
    this.updateTaskStatusByeTypeId(taskItem.Tasks[0].CleaningTypesId, TaskStates.Deleted);
    // this.loadAll();
  }

  accept(taskItem: vwTaskListItem) {
    this.scrollPosition = document.documentElement.scrollTop;
    taskItem.Tasks[0].TimeSinceLastChange = 0;
    taskItem.Tasks[0].TaskState = TaskStates.Accepted;
    this.updateTaskStatusByeTypeId(taskItem.Tasks[0].CleaningTypesId, TaskStates.Accepted);
    var items: vwTaskReadinessItem[] = [];
    let itemToUpdate: vwTaskReadinessItem = new vwTaskReadinessItem();
    itemToUpdate.DateValue = dayjs().format("YYYY.MM.DD HH:mm").toString();
    itemToUpdate.ReadinessCode = TaskStates.Accepted;
    itemToUpdate.TypeId = taskItem.Tasks[0].CleaningTypesId;
    items.push(itemToUpdate);
    this.updateTaskReadiness$ = this.dailyTaskService.updateTaskReadiness(items).subscribe(result => {
      var res = result as RequestResult;
      if (res) {
        if (res.Code === 0) {
          this.hwAlertService.info(this.translate.instant("common.task_inspected"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
          this.loadCleaningTypeByTypeCode$ = this.cleaningTypeService.loadByTypeCode(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()
            , taskItem.TypeCode).subscribe(result => {
              const res = result as RequestResult;
              const data = res.Data as CleaningType;
              if (data.IsRoomClean) {
                let ind = this.allCleaningList.findIndex(item => item.Tasks[0].DailyTaskId == taskItem.Tasks[0].DailyTaskId);
                if (taskItem.Room.RoomStateCode === RoomState.EMPTY_DIRTY) {
                  taskItem.Room.RoomStateCode = RoomState.EMPTY_CLEAN;
                  this.allCleaningList[ind].Room.RoomStateCode = RoomState.EMPTY_AVAILABLE;
                }
                if (taskItem.Room.RoomStateCode === RoomState.RESERVED_DIRTY) {
                  taskItem.Room.RoomStateCode = RoomState.RESERVED_CLEAN;
                  this.allCleaningList[ind].Room.RoomStateCode = RoomState.RESERVED_OK;
                }
                ind = this.cleaningList.findIndex(item => item.Tasks[0].DailyTaskId == taskItem.Tasks[0].DailyTaskId);
                if (taskItem.Room.RoomStateCode === RoomState.EMPTY_DIRTY) {
                  taskItem.Room.RoomStateCode = RoomState.EMPTY_CLEAN;
                  this.cleaningList[ind].Room.RoomStateCode = RoomState.EMPTY_CLEAN;
                }
                if (taskItem.Room.RoomStateCode === RoomState.RESERVED_DIRTY) {
                  taskItem.Room.RoomStateCode = RoomState.RESERVED_OK;
                  this.cleaningList[ind].Room.RoomStateCode = RoomState.RESERVED_OK;
                }

                if (taskItem.Room.RoomStateCode === RoomState.EMPTY_CLEAN) {
                  taskItem.Room.RoomStateCode = RoomState.EMPTY_AVAILABLE;
                  this.allCleaningList[ind].Room.RoomStateCode = RoomState.EMPTY_AVAILABLE;
                }
              }
              // this.applyFilter();
              //this.loadAll();
              this.taskListFilter.filterChanged();
            })
        }
        else {
          this.hwAlertService.error(this.translate.instant("common.error_while_performing_operation"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
        }
      }
    })
  }


  allowCleaning(item: vwTaskListItem) {
    this.scrollPosition = document.documentElement.scrollTop;
    this.setDontDisturb$ = this.dailyTaskService.setDontDisturb(item.Tasks[0].CleaningTypesId, true).subscribe(result => {
      const ress = result as RequestResult

      if (ress.Code === 0) {
        this.hwAlertService.info(this.translate.instant("K200p.cleaning_allowed"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
        const updatedItem = ress.Data as vwCleaningState;
        this.updateItemInList(updatedItem);
        //this.loadAll();
      }
      else {
        this.hwAlertService.error(this.translate.instant("common.error_while_performing_operation"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
      }
    })
  }
  denyCleaning(item: vwTaskListItem) {
    this.scrollPosition = document.documentElement.scrollTop;
    this.setDontDisturb$ = this.dailyTaskService.setDontDisturb(item.Tasks[0].CleaningTypesId, false).subscribe(result => {
      const ress = result as RequestResult;
      if (ress.Code === 0) {
        this.hwAlertService.info(this.translate.instant("K200p.cleaning_denied"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
        const updatedItem = ress.Data as vwCleaningState;
        this.updateItemInList(updatedItem);
        //this.loadAll();
      }
      else {
        this.hwAlertService.error(this.translate.instant("common.error_while_performing_operation"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
      }
    })
  }

  reAssign(taskItem: vwTaskListItem) {
    this.editTask(taskItem)
  }

  modify(taskItem: vwTaskListItem) {
    this.editTask(taskItem)
  }

  editTask(taskItem: vwTaskListItem) {
    this.scrollPosition = document.documentElement.scrollTop;
    if (!taskItem) {
      return
    }
    let id: number;
    id = taskItem.Tasks[0].CleaningTypesId;
    let addTaskParam: AddTaskQueryParam = new AddTaskQueryParam();
    addTaskParam.returnUrl = "";
    addTaskParam.mode = FormOperationMode.OperationModeEdit;
    addTaskParam.dailyTypeId = id;
    if (taskItem.Room.RoomNumber == null) {
      addTaskParam.placeNumber = taskItem.PublicArea.AreaCode;
      addTaskParam.placeTypeCode = "P";
    } else {
      addTaskParam.placeTypeCode = "R";
      addTaskParam.placeNumber = taskItem.Room.RoomNumber;
    }

    const dialogRef = this.addTaskDialog.open(AddTaskDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: "60vw",
      panelClass: "add-task-dialog",
      // height: "80vh",
      data: addTaskParam
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const res = result as RequestResult;
        this.crudMessageService.showAddOrEditMessage(res);
        this.taskListFilter.filterChanged();
        this.loadAll();
        //window.scrollTo(0, this.scrollPosition);
        // this.applyFilter();
      }
    })

  }


  showTaskHistory(item: vwTaskListItem) {
    const dialogRef = this.taskHistoryDialog.open(TaskHistoryDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: "90vw",
      panelClass: "add-task-dialog",
      // height: "80vh",
      data: item
    })
  }


  setCleaningTypeState(taskListItem: vwTaskListItem, newState: TaskStates) {
    //this.loadAll();
  }

  updateTaskStatusByeTypeId(typeId: number, newState: TaskStates) {
    var items: TaskStatus[] = [];
    var item: TaskStatus = new TaskStatus();
    item.DateValue = dayjs().format("YYYY.MM.DD HH:mm:ss");
    item.StatusCode = newState;
    item.TypeId = typeId;
    items.push(item);
    this.updateTaskStatus$ = this.dailyTaskService.updateTaskStatus(items).subscribe(result => {
      var res = result as RequestResult;
      const updatedItem = res.Data as vwCleaningState;
      this.updateItemInList(updatedItem);
      if (res.Code !== 0) {
        this.hwAlertService.error(this.translate.instant("common.error_while_changing"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
      } else {
        let messageExplained: string = "";
        switch (item.StatusCode) {
          case TaskStates.Deleted:
            messageExplained = this.translate.instant("common.task_deleted");
            break;
          case TaskStates.Ended:
            messageExplained = this.translate.instant("common.task_ended");
            break;
          case TaskStates.Paused:
            messageExplained = this.translate.instant("common.task_pause");
            break;
          case TaskStates.Running:
            messageExplained = this.translate.instant("common.task_started");
            break;
          case TaskStates.Accepted:
            messageExplained = this.translate.instant("common.task_inspected");
            break;
        }
        this.hwAlertService.info(messageExplained, new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
      }
      //this.loadAll();
    })
  }
  updateSelectedItemTaskStatus(newState: TaskStates, taskListItem: vwTaskListItem) {
    var items: TaskStatus[] = [];
    var item: TaskStatus = new TaskStatus();
    item.DateValue = dayjs().format("YYYY.MM.DD HH:mm:ss");
    item.StatusCode = newState;
    item.TypeId = taskListItem.Tasks[0].CleaningTypesId;
    items.push(item);
    this.updateTaskStatus$ = this.dailyTaskService.updateTaskStatus(items).subscribe(result => {
      var res = result as RequestResult;
      if (res.Code !== 0) {
        this.hwAlertService.error(this.translate.instant("common.error_while_performing_operation"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
      }
      else {

        const updatedItem = res.Data as vwCleaningState;
        this.updateItemInList(updatedItem);
        let messageExplained: string = "";
        switch (item.StatusCode) {
          case TaskStates.Deleted:
            messageExplained = this.translate.instant("common.task_deleted");
            break;
          case TaskStates.Ended:
            messageExplained = this.translate.instant("common.task_ended");
            break;
          case TaskStates.Paused:
            messageExplained = this.translate.instant("common.task_pause");
            break;
          case TaskStates.Running:
            messageExplained = this.translate.instant("common.task_started");
            break;
          case TaskStates.Accepted:
            messageExplained = this.translate.instant("common.task_inspected");
            break;
        }
        this.hwAlertService.info(messageExplained, new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
        this.taskListFilter.filterChanged();
      }
    });
  }



  updateItemInList(item: vwCleaningState) {
    if (item.VwCleaningList.length > 0) {
      const cleaningId = item.VwCleaningList[0].Tasks[0].CleaningTypesId;
      let index = -1;
      for (var i = 0; i < this.allCleaningList.length; i++) {
        if (this.allCleaningList[i].Tasks[0].CleaningTypesId == cleaningId) {
          index = i
        }
      }
      if (index !== -1) {
        this.allCleaningList[index] = JSON.parse(JSON.stringify(item.VwCleaningList[0])) as vwTaskListItem;
        this.taskListFilter.filterChanged();
      }
    }
    if (item.VwMaintenanceList.length > 0) {
      const maintenanceId = item.VwMaintenanceList[0].Tasks[0].CleaningTypesId;
      let index = -1;
      for (var i = 0; i < this.allMaintenanceList.length; i++) {
        if (this.allMaintenanceList[i].Tasks[0].CleaningTypesId == maintenanceId) {
          index = i
        }
      }
      if (index !== -1) {
        this.allMaintenanceList[index] = JSON.parse(JSON.stringify(item.VwMaintenanceList[0])) as vwTaskListItem;
        this.taskListFilter.filterChanged();
      }
    }
  }

  //#region ======  Maintenance status change & actions ========== 

  startMaintenance(taskItem: vwTaskListItem) {
    taskItem.Tasks[0].TaskState = TaskStates.Running;
    taskItem.Tasks[0].TimeSinceLastChange = 0;
    this.setMaintenanceTypeState(taskItem, TaskStates.Running);
    this.maintenanceList = JSON.parse(JSON.stringify(this.maintenanceList));
    this.updateSelectedItemTaskStatus(TaskStates.Running, taskItem);
  }

  pauseMaintenance(taskItem: vwTaskListItem) {
    taskItem.Tasks[0].TaskState = TaskStates.Paused;
    taskItem.Tasks[0].TimeSinceLastChange = 0;
    this.setMaintenanceTypeState(taskItem, TaskStates.Paused);
    this.maintenanceList = JSON.parse(JSON.stringify(this.maintenanceList));
    this.updateSelectedItemTaskStatus(TaskStates.Paused, taskItem);
  }

  endMaintenance(taskItem: vwTaskListItem) {
    taskItem.Tasks[0].TaskState = TaskStates.Ended;
    taskItem.Tasks[0].TimeSinceLastChange = 0;
    this.setMaintenanceTypeState(taskItem, TaskStates.Ended);
    this.maintenanceList = JSON.parse(JSON.stringify(this.maintenanceList));
    this.updateSelectedItemTaskStatus(TaskStates.Ended, taskItem);
  }

  acceptMaintenance(taskItem: vwTaskListItem) {
    taskItem.Tasks[0].TaskState = TaskStates.Accepted;
    taskItem.Tasks[0].TimeSinceLastChange = 0;
    this.setMaintenanceTypeState(taskItem, TaskStates.Accepted);
    this.maintenanceList = JSON.parse(JSON.stringify(this.maintenanceList));
    this.updateSelectedItemTaskStatus(TaskStates.Accepted, taskItem);


  }

  setMaintenanceTypeState(taskListItem: vwTaskListItem, newState: TaskStates) {
    let ind: number = this.allMaintenanceList.findIndex(item => item.Tasks[0].CleaningTypesId == taskListItem.Tasks[0].CleaningTypesId);
    this.allMaintenanceList[ind].Tasks.forEach(item => {
      item.TaskState = newState;
    });
    ind = this.maintenanceList.findIndex(item => item.Tasks[0].CleaningTypesId == taskListItem.Tasks[0].CleaningTypesId);
    this.maintenanceList[ind].Tasks.forEach(item => {
      item.TaskState = newState;
    })
  }
  showCamera(item: vwTaskListItem) {
    this.selectedTaskItem = item;
    this.cameraPanelVisible = true;
    //this.cameraPanel.show(event,"main");
    const dialogRef = this.photoDialog.open(PhotoDialogComponent,
      {
        disableClose: true,
        hasBackdrop: true,
        minWidth: '100vw',
        minHeight: '100vh',
        data: item
        // height: "350px"
      })
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.imageManagerService.uploadImage(result, item.Tasks[0].DailyTaskId).subscribe(result => {

        })
      }
    })
  }
  // endregion======================================================

  menuClicked(event) {
    this.scrollPosition = document.documentElement.scrollTop;
  }
  cameraPanelStyle(camVisible: boolean) {
    let styles = {};
    if (camVisible) {
      styles["visibility"] = "visible";
      styles["z-index"] = "1000";
      styles["width"] = "100%";
      styles["height"] = "100%";
      styles["position"] = "absolute";
      styles["left"] = "0";
      styles["top"] = "0";
      styles["position"] = "fixed";
    }
    else {
      styles["visibility"] = "hidden";
      styles["z-index"] = "0";
      styles["position"] = "fixed";
    }
    return styles;

  }

  closePhotoDialog() {
    this.cameraManagerService.StopCamera();
    this.cameraPanelVisible = false;
    // 
  }

  getCleaningTaskId(cleaningType: vwTaskListItem) {
    if (cleaningType.Tasks) {
      return cleaningType.Tasks[0].CleaningTypesId;
    }
    else {
      return (0)
    }
  }

  mustShowTaskDiffs(): boolean {
    let ret: boolean = false;
    if (this.taskDiffs.length > 0 && ((!this.roleService.userIsInRole(RoleType.MUSZAKI_VEZETO)) && !this.roleService.userIsInRole(RoleType.MUSZAKOS))) {
      return true
    } else {
      return false;
    }

    return ret
  }

  //#region  export */

  exportToXls() {
    let self = this;
    // this.spinnerService.setSpinnerVisibility(true);
    // this.serverProcessing = true;
    const fileName = this.translate.instant("K200p.title") + "-" + this.froDatum + ".xlsx";
    let filter: vwTaskListFilter = this.buildFilterObj();
    this.dailyTaskService.getTaskListExcel(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId(), filter).subscribe(fileData => {
      let b: any = new Blob([fileData], { type: 'application/xls' });
      // this.spinnerService.setSpinnerVisibility(false);
      // this.serverProcessing = false;
      saveAs(b, fileName),
        error => {
          console.log(error);
          // this.spinnerService.setSpinnerVisibility(false);
          // this.serverProcessing = false;
        };

    })

  }

  exportToPdf() {
    let self = this;
    // this.spinnerService.setSpinnerVisibility(true);
    // this.serverProcessing = true;
    const fileName = this.translate.instant("K200p.title") + "-" + this.froDatum + ".pdf";
    let filter: vwTaskListFilter = this.buildFilterObj();

    this.dailyTaskService.getTaskListPdf(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId(), filter).subscribe(fileData => {
      let b: any = new Blob([fileData], { type: 'application/pdf' });
      var fileURL = URL.createObjectURL(b);
      window.open(fileURL);
      // this.spinnerService.setSpinnerVisibility(false);
      // this.serverProcessing = false;

      saveAs(b, fileName),
        error => {
          console.log(error);
          // this.spinnerService.setSpinnerVisibility(false);
          // this.serverProcessing = false;
        };
    })
  }

  exportByResponsibility() {
    let self = this;
    // this.spinnerService.setSpinnerVisibility(true);
    // this.serverProcessing = true;
    const fileName = this.translate.instant("K200p.list_by_responsibility") + "-" + this.froDatum + ".pdf";
    let filter: vwTaskListFilter = this.buildFilterObj();

    this.dailyTaskService.getTaskByResponsibility(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId(), filter).subscribe(fileData => {
      let b: any = new Blob([fileData], { type: 'application/pdf' });
      var fileURL = URL.createObjectURL(b);
      window.open(fileURL);
      // this.spinnerService.setSpinnerVisibility(false);
      // this.serverProcessing = false;

      saveAs(b, fileName),
        error => {
          console.log(error);
          // this.spinnerService.setSpinnerVisibility(false);
          // this.serverProcessing = false;
        };

    })
  }

  getMaintenanceListPdf() {
    let self = this;
    // this.spinnerService.setSpinnerVisibility(true);
    // this.serverProcessing = true;
    const fileName = this.translate.instant("K200p.title") + "-" + this.froDatum + ".pdf";
    let filter: vwTaskListFilter = this.buildFilterObj();

    this.getMaintenanceListPdf$ = this.dailyTaskService.getMaintenanceListPdf(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId(), filter).subscribe(fileData => {
      let b: any = new Blob([fileData], { type: 'application/pdf' });
      var fileURL = URL.createObjectURL(b);
      window.open(fileURL);
      // this.spinnerService.setSpinnerVisibility(false);
      // this.serverProcessing = false;

      saveAs(b, fileName),
        error => {
          console.log(error);
          // this.spinnerService.setSpinnerVisibility(false);
          // this.serverProcessing = false;
        };

    })
  }
  //#endregion


  //#region utils
  buildFilterObj(): vwTaskListFilter {
    let filter = this.taskListFilter.getFilter();
    return filter;
  }

  formatMinutes(minutes: number) {
    let result: string = "";
    if (minutes >= 576) {
      result = Math.floor(minutes / 1440).toString() + " " + this.translate.instant("common.day_ago")
    }
    if (minutes >= 60 && minutes < 1440) {
      result = Math.floor(minutes / 60).toString() + " " + this.translate.instant("common.hour_ago");
    }

    if (minutes >= 0 && minutes <= 59) {
      result = minutes + " " + this.translate.instant("common.minute_ago");
    }
    return result;
  }

  // sort functions
  compareByAssignedTo(item1: vwTaskListItem, item2: vwTaskListItem) {
    return new Intl.Collator('hu-HU', { sensitivity: 'variant', usage: 'sort' }).compare(item1.AssignedToRealName, item2.AssignedToRealName)
  }

  compareBySupervisor(item1: vwTaskListItem, item2: vwTaskListItem) {
    return new Intl.Collator('hu-HU', { sensitivity: 'variant', usage: 'sort' }).compare(item1.InspectorName, item2.InspectorName)
  }
  compareByRoomNumber(item1: vwTaskListItem, item2: vwTaskListItem) {
    if (item1.Room.RoomNumber < item2.Room.RoomNumber) {
      return -1;
    }
    if (item1.Room.RoomNumber > item2.Room.RoomNumber) {
      return 1;
    }
    return 0;
  }

  compareByTaskName(item1: vwTaskListItem, item2: vwTaskListItem) {
    const coll = new Intl.Collator('hu', { sensitivity: 'variant', usage: 'sort' }).compare(item1.TypeName, item2.TypeName)
    console.log(item1.TypeName, item2.TypeName,)
    return new Intl.Collator('hu', { sensitivity: 'variant', usage: 'sort' }).compare(item1.TypeName, item2.TypeName)
  }

  compareByTaskDate(item1: vwTaskListItem, item2: vwTaskListItem) {

    if (item1.Tasks[0].DailyTaskId < item2.Tasks[0].DailyTaskId) {
      return -1;
    }
    if (item1.Tasks[0].DailyTaskId > item2.Tasks[0].DailyTaskId) {
      return 1;
    }
    return 0;

    //return  new Intl.Collator('hu-HU',{ sensitivity: 'variant' , usage:'sort'}).compare(item1.TypeName , item2.TypeName)
  }
  //#endregion =================================================================================================================

}


class PhotoItem {
  previewImageSrc: string;
  thumbnailImageSrc: string;
  alt: string;
  title: string;
}

