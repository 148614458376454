
// Angular
import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
// 3rdparty
import { TranslateService } from '@ngx-translate/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subject, Subscription, combineLatest, map, takeUntil } from 'rxjs';


// Hostware
import { MatSelectChange } from '@angular/material/select';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatTabGroup } from '@angular/material/tabs';
import { RequestResult } from '@app/core/dto/RequestResult';
import { FormOperationMode } from '@app/core/enums/form-operation-mode';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { AddTaskQueryParam } from '@app/kip/classes/AddTaskParam';
import { CleaningType } from '@app/kip/dto/CleaningType';
import { KipUser } from '@app/kip/dto/KipUser';
import { MaintenanceType } from '@app/kip/dto/MaintenanceType';
import { PublicArea } from '@app/kip/dto/PublicArea';
import { Room } from '@app/kip/dto/Room';
import { AddTaskService } from '@app/kip/services/add-task.service';
import { CleaningTypeService } from '@app/kip/services/cleaning-type.service';
import { DailyTaskService } from '@app/kip/services/daily-task.service';
import { HokParamsService } from '@app/kip/services/hok-params.service';
import { KipUserService } from '@app/kip/services/kip-user.service';
import { MaintenanceTypeService } from '@app/kip/services/maintenance-type.service';
import { PublicAreaService } from '@app/kip/services/public-area.service';
import { RoomService } from '@app/kip/services/room.service';
import { vwTaskListItem } from '@app/kip/viewdto/vwCleaningState/vwTaskListItem';
import { vwDailyTypeTaskList } from '@app/kip/viewdto/vwDailyTypeTaskList/vwDailyTypeTaskList';
import { vwTaskId } from '@app/kip/viewdto/vwDailyTypeTaskList/vwTaskId';
import { VwNewDailyTask } from '@app/kip/viewdto/vwNewDailyTask';
import { RoleType } from '@app/shared/enums/roles';
import HwUtils from '@app/shared/helpers/hwutils';
import { RoleService } from '@app/shared/services/role.service';
import dayjs from 'dayjs';

@AutoUnsubscribe()
@Component({
  selector: 'add-task-dialog',
  templateUrl: './add-task-dialog.component.html',
  styleUrls: ['./add-task-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class AddTaskDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('tabGroup', { static: true }) tabGroup: MatTabGroup;

  _data: AddTaskQueryParam;
  dueDate: string;

  roomSelectionVisible: boolean;
  cleaningTypeTaskList: vwDailyTypeTaskList[] = [];
  cleaningTypes: CleaningType[] = [];
  selectedCleaningTasks: vwTaskId[] = [];
  selectedMaintenanceTasks: vwTaskId[] = [];
  maintenanceTypeTaskList: vwDailyTypeTaskList[] = [];

  taskToModify: vwTaskListItem;
  maintenanceTypes: MaintenanceType[] = [];
  kipUsers: KipUser[] = [];
  kipUsersFiltered : KipUser[] = [];
  supervisors: KipUser[] = [];
  publicAreas: PublicArea[] = [];
  rooms: Room[] = [];

  loadFroDatum$: Subscription;
  hotelUnitChanged$: Subscription;
  destroy$: Subject<boolean> = new Subject<boolean>();
  loaded: boolean;
  tasksVisible: boolean;
  editorForm: FormGroup;
  title: string;
  addDailyTask$: Subscription;
  updateDailyTask$: Subscription;

   noUser: KipUser = new KipUser();
 

  constructor(public dialogRef: MatDialogRef<AddTaskDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: AddTaskQueryParam,
    private cleaningTypeService: CleaningTypeService,
    private maintenanceTypeService: MaintenanceTypeService,
    private hokParamsService: HokParamsService,
    private kipUserService: KipUserService,
    private roleService: RoleService,
    private roomService: RoomService,
    private publicAreaService: PublicAreaService,
    private addTaskService: AddTaskService,
    private sessionStorageService: SessionStorageService,
    private translate: TranslateService,
    private dailyTaskService: DailyTaskService,
    private fb: FormBuilder,
    private dateAdapter: DateAdapter<Date>,
    private changeDetectorRef: ChangeDetectorRef,



  ) {
    this._data = data;
    this.title = this._data.mode == 'add' ? this.translate.instant("K201p.creat_task") : this.translate.instant("common.task_change")

    this.editorForm = this.fb.group({
      CleaningTypeId: new FormControl('0'),
      MaintenanceTypeId: new FormControl('0'),
      AssignedTo: new FormControl('0'),
      Supervisor: new FormControl('0'),
      Comment: new FormControl(''),
      PlaceType: new FormControl(true),
      RoomId: new FormControl(),
      PublicAreaId: new FormControl(),
      DueDate: new FormControl(dayjs(), Validators.required),
      DueTimeHours: new FormControl('00', Validators.required),
      DueTimeMinutes: new FormControl("00", Validators.required)

    })
  }

  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
    this.noUser.UserId = -1;
    this.noUser.UserName = this.translate.instant("common.not_assigned");
    this.noUser.RealName = this.translate.instant("common.not_assigned");
    this.noUser.Subscriber = "";
    this.noUser.HotelId = this.sessionStorageService.getSelectedHotelId();
    this.noUser.UserCode = ''
    this.noUser.UserUuId = '';
    this.noUser.Data = '';
    this.noUser.Active = true;
    this.dateAdapter.setLocale("hu")
    this.loadFroDatum$ = this.hokParamsService.loadFroDatum(this.sessionStorageService.getSelectedUnit(),
      this.sessionStorageService.getSelectedHotelId()).subscribe(result => {
        const res = result as RequestResult;
        this.dueDate = res.Data;
        this.loadAll();


      })
  }

  ngAfterViewInit(): void {
    this.changeDetectorRef.detectChanges();
    // setTimeout(() => {
    //   let a = this.editorForm.controls["DueTime"].value;
    //   // this.editorForm.controls["DueTime"].setValue("23:23")
    //   // this.renderer2.selectRootElement("#dueTime").focus() 

    //   // this.changeDetectorRef.detectChanges();
    // }, 1000);  
  }
  ngOnDestroy() {
    this.loadFroDatum$?.unsubscribe();
    this.addDailyTask$?.unsubscribe();
    this.updateDailyTask$?.unsubscribe();
    this.destroy$.next(true);
    this.destroy$?.unsubscribe();
  }
  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  cancel() {
    this.dialogRef.close(undefined);
  }
  submit() {

    let newDailyTask: VwNewDailyTask = new VwNewDailyTask();
    // if (this.editorForm.controls["AssignedTo"].value == "-1") {
    newDailyTask.UserId = parseInt(this.editorForm.controls["AssignedTo"].value) == -1 ? null : parseInt(this.editorForm.controls["AssignedTo"].value);
    newDailyTask.SupervisorId = parseInt(this.editorForm.controls["Supervisor"].value) == -1 ? null : parseInt(this.editorForm.controls["Supervisor"].value);
    //}
    newDailyTask.Comment = this.editorForm.controls["Comment"].value;
    newDailyTask.AreaTypeCode = this.roomSelectionVisible ? "R" : "P";
    if (this.roomSelectionVisible) {
      newDailyTask.AreaNumber = parseInt(this.editorForm.controls["RoomId"].value);
    } else {
      newDailyTask.AreaNumber = parseInt(this.editorForm.controls["PublicAreaId"].value);
    }
    newDailyTask.HotelId = this.sessionStorageService.getSelectedHotelId();
    newDailyTask.Subscriber = this.sessionStorageService.getSelectedUnit();
    // const tim = this.editorForm.controls["DueTime"].value;
    // const timDayJs = dayjs(dayjs(tim).format("YYYY.MM.DD HH:mm"));

    // const newMinute = HwUtils.leftPad((this.editorForm.controls["DueTime"].value), 2);
    // const newHour = HwUtils.leftPad((this.editorForm.controls["DueTime"].value).getHours(), 2);

    newDailyTask.DueDate = dayjs(this.editorForm.controls["DueDate"].value).format("YYYY.MM.DD") + " " +
      HwUtils.leftPad(this.editorForm.controls["DueTimeHours"].value, 2) + ":" +
      HwUtils.leftPad(this.editorForm.controls["DueTimeMinutes"].value, 2);
    // newDailyTask.DueDate = dayjs(this.editorForm.controls["DueDate"].value).format("YYYY.MM.DD") + " " + this.editorForm.controls["DueTime"].value;

    if (this.tabGroup.selectedIndex == 0) { // Cleaning
      newDailyTask.TypeCode = "C";
      newDailyTask.TypeId = parseInt(this.editorForm.controls["CleaningTypeId"].value);
    } else {
      newDailyTask.TypeCode = "M";
      newDailyTask.TypeId = parseInt(this.editorForm.controls["MaintenanceTypeId"].value);
    }

    if (this._data.mode == FormOperationMode.OperationModeAdd) {
      this.addDailyTask$ = this.addTaskService.addTask(newDailyTask).subscribe(result => {
        const res = result as RequestResult;
        this.dialogRef.close(res)
      })
    } else {
      newDailyTask.DailyTypeId = this._data.dailyTypeId;
      this.updateDailyTask$ = this.dailyTaskService.updateDailyTask(newDailyTask).subscribe(result => {
        const res = result as RequestResult;
        this.dialogRef.close(res);
      })
    }

  }
  toggleTasks() {
    this.tasksVisible = !this.tasksVisible;
  }
  cleaningTypeSelectionChanged(event: MatSelectChange) {
    this.selectedCleaningTasks = [];
    this.cleaningTypeTaskList.forEach(group => {
      group.Items.forEach(type => {
        if (type.Id == parseInt(event.value)) {
          this.selectedCleaningTasks = [...type.Items];
        }
      })
    });
  }

  maintenanceTypeSelectionChanged(event: MatSelectChange) {
    this.selectedMaintenanceTasks = [];
    this.maintenanceTypeTaskList.forEach(group => {
      group.Items.forEach(type => {
        if (type.Id == parseInt(event.value)) {
          this.selectedMaintenanceTasks = [...type.Items];
        }
      })
    });
  }
  placeTypeChanged(event: MatSlideToggleChange) {
    this.roomSelectionVisible = event.checked;
  }
  placeTypeName(event: MatSlideToggle): string {
    return event.checked ? this.translate.instant("common.rooms") : this.translate.instant("common.public_areas")
  }

  tabSelectionChanged(newIndex: number) {
    // this.refreshSupervisorCombo();
  }
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  loadAll() {
    combineLatest({
      cleaningTypes: this.cleaningTypeService.loadAllOrderbyGrouIdAndTypeName(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()),
      maintenanceTypes: this.maintenanceTypeService.loadAllOrderbyGrouIdAndTypeName(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()),
      kipUsers: this.loadAllKipUsers(),
      rooms: this.roomService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId(), this.dueDate),
      publicAreas: this.publicAreaService.loadAll(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId()),
      taskState: this.dailyTaskService.loadOneTaskState(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId(), this._data.dailyTypeId)
    }).pipe(takeUntil(this.destroy$)).subscribe(
      value => {
        this.cleaningTypeTaskList = (value.cleaningTypes as RequestResult).Data as (Array<vwDailyTypeTaskList>);
        this.cleaningTypeTaskList.forEach(item => {
          if (this._data.dailyTypeId == -1) {
            this.editorForm.controls["CleaningTypeId"].patchValue(this.cleaningTypeTaskList[0].Items[0].Id);
            this.selectedCleaningTasks = this.cleaningTypeTaskList[0].Items[0].Items;


          } else {
            this.cleaningTypeTaskList.forEach(group => {
              group.Items.forEach(type => {
                if (type.Id == this._data.dailyTypeId) {
                  this.selectedCleaningTasks == type.Items
                }
              })
            });
          }
          this.loaded = true;
        });
        this.maintenanceTypeTaskList = (value.maintenanceTypes as RequestResult).Data as Array<vwDailyTypeTaskList>;
        this.maintenanceTypeTaskList.forEach(item => {
          if (this._data.dailyTypeId == -1) {
            this.editorForm.controls["MaintenanceTypeId"].patchValue(this.maintenanceTypeTaskList[0].Items[0].Id);
            this.selectedMaintenanceTasks = this.maintenanceTypeTaskList[0].Items[0].Items;
          } else {
            this.maintenanceTypeTaskList.forEach(group => {
              group.Items.forEach(type => {
                if (type.Id == this._data.dailyTypeId) {
                  this.selectedMaintenanceTasks == type.Items
                }
              })
            });
          }
          this.loaded = true;
        });

        this.kipUsers = (value.kipUsers as RequestResult).Data as (Array<KipUser>);
        if(this._data.dailyTypeId == -1) {
          this.kipUsers=this.kipUsers.filter(item=>item.Active==true)          
        }

        let  kipus = (value.kipUsers as RequestResult).Data as (Array<KipUser>);
        if(this._data.dailyTypeId == -1) {
          kipus=kipus.filter(item=>item.Active==true)          
        }
        kipus.forEach(kipuser => {
          if (this.roleService.isUserInRole(kipuser.UserName, RoleType.GONDNOKNO) ||
            this.roleService.isUserInRole(kipuser.UserName, RoleType.MUSZAKI_VEZETO)) {
            this.supervisors.push(JSON.parse(JSON.stringify(kipuser)));
          }
        })
        this.kipUsers.splice(0, 0, this.noUser);
        this.rooms = (value.rooms as RequestResult).Data as Array<Room>;
        this.publicAreas = (value.publicAreas as RequestResult).Data as Array<PublicArea>;
        if (this._data.dailyTypeId == -1) {
          // inaktív userek kiszűrése
          if (this.kipUsers.length === 1) {
            this.editorForm.controls["AssignedTo"].setValue(this.kipUsers[1].UserId);
          } else {
            this.editorForm.controls["AssignedTo"].setValue(-1);
          }
          // this.editorForm.controls["Supervisor"].setValue(-1);
          this.editorForm.controls["PlaceType"].setValue(this._data.placeTypeCode == "R" ? true : false);
          this.roomSelectionVisible = this._data.placeTypeCode == "R" ? true : false;

          if (this._data.placeNumber !== "-1" && this._data.placeTypeCode == "R") {
            //this.rooms.find(item=> item.RoomNumber ==this._data.placeNumber).RoomId)
            this.editorForm.controls["RoomId"].setValue(this.rooms.find(item => item.RoomNumber == this._data.placeNumber).RoomId);
          } else {
            this.editorForm.controls["RoomId"].setValue(this.rooms[0].RoomId)
          }


          if (this._data.placeNumber !== "-1" && this._data.placeTypeCode == "P") {
            this.editorForm.controls["PublicAreaId"].setValue(this.publicAreas.find(item => item.AreaCode == this._data.placeNumber).PublicAreaId);
          } else {
            this.editorForm.controls["PublicAreaId"].setValue(this.publicAreas[0].PublicAreaId);
          }


          this.editorForm.controls["DueDate"].setValue(dayjs(this.dueDate + " 00:00", "YYYY.MM.DD HH:mm"));
          this.editorForm.controls["DueTimeHours"].setValue("00");
          this.editorForm.controls["DueTimeMinutes"].setValue("00");

          if (this.roleService.userIsInRole(RoleType.MUSZAKI_VEZETO) || this.roleService.userIsInRole(RoleType.MUSZAKOS)) {
            setTimeout(() => {
              this.tabGroup.selectedIndex = 1
              this.refreshSupervisorCombo();
              this.refreshUserCombo()
              const ind = this.supervisors.findIndex(item => item.Active == true)
              this.editorForm.controls["Supervisor"].setValue(this.supervisors[ind].UserId)
            }, 500)


          } else {
            setTimeout(() => {
              this.tabGroup.selectedIndex = 0
              this.refreshSupervisorCombo();
              this.refreshUserCombo();
              const ind = this.supervisors.findIndex(item => item.Active == true)
              this.editorForm.controls["Supervisor"].setValue(this.supervisors[ind].UserId)
            }, 500)

          }
        } else {
          this.taskToModify = (value.taskState as RequestResult).Data as vwTaskListItem;
          if (this.taskToModify.TypeTimeStr == "") {
            this.taskToModify.TypeTimeStr = "00:00"
          }
          this.editorForm.controls["PlaceType"].setValue(this._data.placeTypeCode == "R" ? true : false);
          this.roomSelectionVisible = this._data.placeTypeCode == "R" ? true : false;
          if (this._data.placeTypeCode == "R") {
            this.editorForm.controls["RoomId"].setValue(this.rooms.find(item => item.RoomNumber == this._data.placeNumber).RoomId)
          } else {
            this.editorForm.controls["PublicAreaId"].setValue(this.publicAreas.find(item => item.AreaCode == this._data.placeNumber).PublicAreaId);
          }
          this.editorForm.controls["DueDate"].setValue(dayjs(this.taskToModify.DueDate));
          const c = dayjs(this.taskToModify.DueDate + " " + this.taskToModify.TypeTimeStr + ".00", "YYYY.MM.DD HH:mm:SS");
          if (this.taskToModify.TipusType == "C") {
            this.editorForm.controls["CleaningTypeId"].setValue(this.taskToModify.TypeId);

          } else {
            this.editorForm.controls["MaintenanceTypeId"].setValue(this.taskToModify.TypeId)
          }
          
          this.refreshSupervisorCombo();
          this.refreshUserCombo();
          if (this.taskToModify.TipusType == "M") {
            setTimeout(() => {
              this.tabGroup.selectedIndex = 1;

              const ind = this.supervisors.findIndex(item => item.Active == true)
              this.editorForm.controls["AssignedTo"].setValue(this.taskToModify.AssignedToId == null ? -1 : this.taskToModify.AssignedToId);
              this.editorForm.controls["Supervisor"].setValue(this.taskToModify.InspectorId == null ? this.editorForm.controls["Supervisor"].setValue(this.supervisors[ind].UserId) : this.taskToModify.InspectorId)
              this.editorForm.controls["Comment"].setValue(this.taskToModify.Comment);
              this.editorForm.controls["DueTimeHours"].setValue(this.taskToModify.TypeTimeStr.split(':')[0])
              this.editorForm.controls["DueTimeMinutes"].setValue(this.taskToModify.TypeTimeStr.split(':')[1])
            }, 500)

          } else {
            setTimeout(() => {
              this.tabGroup.selectedIndex = 0
              const ind = this.supervisors.findIndex(item => item.Active == true)
              this.editorForm.controls["AssignedTo"].setValue(this.taskToModify.AssignedToId == null ? -1 : this.taskToModify.AssignedToId);
              this.editorForm.controls["Supervisor"].setValue(this.taskToModify.InspectorId == null ? this.editorForm.controls["Supervisor"].setValue(this.supervisors[ind].UserId) : this.taskToModify.InspectorId)
              this.editorForm.controls["Comment"].setValue(this.taskToModify.Comment);
              this.editorForm.controls["DueTimeHours"].setValue(this.taskToModify.TypeTimeStr.split(':')[0])
              this.editorForm.controls["DueTimeMinutes"].setValue(this.taskToModify.TypeTimeStr.split(':')[1])
            }, 500)
          }
          this.editorForm.controls["RoomId"].disable();
          this.editorForm.controls["PlaceType"].disable();
          if (this.taskToModify.Room.RoomStateTransitionCode != "" && this.taskToModify.Room.RoomStateTransitionCode !== null) {
            this.editorForm.controls["DueDate"].disable();
            this.editorForm.controls["DueTimeHours"].disable();
            this.editorForm.controls["DueTimeMinutes"].disable();
          }
        }
      })
  }

  loadAllKipUsers() {
    let units: string;
    let hotels: string;
    if (this.sessionStorageService.multiHotel) {
      if (!this.sessionStorageService.IgnoreMultiHotel) {
        const sel: string[] = this.sessionStorageService.getFlattenedHotels()[0].value.toString().split("@")
        units = sel[0] + ',' + this.sessionStorageService.getSelectedUnit();
        hotels = sel[1] + ',' + this.sessionStorageService.getSelectedHotelId().toString()
      } else {
        units = this.sessionStorageService.getSelectedUnit();
        hotels = this.sessionStorageService.getSelectedHotelId().toString();
      }
    } else { // nem multihotel, csak a kiválasztott
      units = this.sessionStorageService.getSelectedUnit();
      hotels = this.sessionStorageService.getSelectedHotelId().toString();
    }

    return this.kipUserService.loadAllMulti(units, hotels).pipe(map((result) => {
      return result;
    }));
  }


  refreshCombos() {
    this.refreshSupervisorCombo();
    this.refreshUserCombo();
  }
  refreshSupervisorCombo() {
    this.supervisors = [];
    this.supervisors.push(this.noUser)
    this.kipUsers.forEach(u => {
      if (this.taskToModify) {
        if (this.taskToModify.TipusType == "C") {
          if (this.roleService.isUserInRole(u.UserName, RoleType.GONDNOKNO)) {
            this.supervisors.push(u);
          }
        } else {
          if (this.roleService.isUserInRole(u.UserName, RoleType.MUSZAKI_VEZETO)) {
            this.supervisors.push(u);
          }
        }
      } else {
        if (this.tabGroup.selectedIndex == 1) {
          if (this.roleService.isUserInRole(u.UserName, RoleType.MUSZAKI_VEZETO)) {
            this.supervisors.push(u);
          }
        } else {
          if (this.roleService.isUserInRole(u.UserName, RoleType.GONDNOKNO)) {
            this.supervisors.push(u);
          }
        }
      }
    })
    // find the first Active user
    // const ind = this.supervisors.findIndex(item => item.Active == true)
    // this.editorForm.controls["Supervisor"].setValue(this.supervisors[ind].UserId)
    // this.editorForm.controls["DueTimeHours"].disable();
    this.editorForm.controls["DueTimeHours"].setValue(this.taskToModify?.TypeTimeStr.split(':')[0] ? this.taskToModify.TypeTimeStr?.split(':')[0] : '0')
    this.editorForm.controls["DueTimeMinutes"].setValue(this.taskToModify?.TypeTimeStr.split(':')[1] ? this.taskToModify.TypeTimeStr?.split(':')[1] : '0')

  }

  refreshUserCombo() {
    this.kipUsersFiltered = [];
    this.kipUsersFiltered.push(this.noUser)
    this.kipUsers.forEach(u => {
      if (this.taskToModify) {
        if (this.taskToModify.TipusType == "C") {
          if (this.roleService.isUserInRole(u.UserName, RoleType.TAKARITO)) {
            this.kipUsersFiltered.push(u);
          }
          if( this.roleService.userIsInRole(RoleType.GONDNOKNO) && this.roleService.isUserInRole(u.UserName, RoleType.GONDNOKNO)) {
            this.kipUsersFiltered.push(u);
          }
        } else {
          if (this.roleService.isUserInRole(u.UserName, RoleType.MUSZAKOS)) {
            this.kipUsersFiltered.push(u);
          } else {
            if( this.roleService.userIsInRole(RoleType.MUSZAKI_VEZETO) && this.roleService.isUserInRole(u.UserName, RoleType.MUSZAKI_VEZETO)) {
              this.kipUsersFiltered.push(u);
            }
          }
        }
      } else {
        if (this.tabGroup.selectedIndex == 1) {
          if (this.roleService.isUserInRole(u.UserName, RoleType.MUSZAKOS)) {
            this.kipUsersFiltered.push(u);
          }
          if( this.roleService.userIsInRole(RoleType.MUSZAKI_VEZETO) && this.roleService.isUserInRole(u.UserName, RoleType.MUSZAKI_VEZETO)) {
            this.kipUsersFiltered.push(u);
          }
        } else {
          if (this.roleService.isUserInRole(u.UserName, RoleType.TAKARITO)) {
            this.kipUsersFiltered.push(u);
          }
          if( this.roleService.userIsInRole(RoleType.GONDNOKNO) && this.roleService.isUserInRole(u.UserName, RoleType.GONDNOKNO)) {
            this.kipUsersFiltered.push(u);
          }
        }
      }
    })
    // find the first Active user
    // const ind = this.kipUsersFiltered.findIndex(item => item.Active == true)
    // this.editorForm.controls["AssignedTo"].setValue(this.kipUsersFiltered[ind].UserId)
  }
  isCleaningDisabled(): boolean {
    if (this.taskToModify) {
      if (this.taskToModify.TipusType == "C") {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  isMaintenanceDisabled(): boolean {
    if (this.taskToModify) {
      if (this.taskToModify.TipusType == "M") {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
   
  hello(event) {
    let a = this.editorForm;
  }

  //#endregion =================================================================================================================
}
