import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SessionStorageService } from '@app/core/services/session-storage.service';


@Component({
  selector: 'calendar-header',
  templateUrl: './calendar-header.component.html',
  styleUrls: ['./calendar-header.component.scss']
})
export class CalendarHeaderComponent implements OnInit {

  @Input() view: string;

  @Input() viewDate: Date;

  @Input() locale: string = ""

  @Output() viewChange: EventEmitter<string> = new EventEmitter();

  @Output() viewDateChange: EventEmitter<Date> = new EventEmitter();


  constructor(private sessionStorageService : SessionStorageService) { }

  ngOnInit() {
    this.locale = this.sessionStorageService.getUserLanguage()
  }

}

