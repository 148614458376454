import { Component, OnInit } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { vwTaskListItem } from '@app/kip/viewdto/vwCleaningState/vwTaskListItem';
import { Inject } from '@angular/core';
import { TaskStates } from '@app/kip/enums/TaskStates';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'task-history-dialog',
  templateUrl: './task-history-dialog.component.html',
  styleUrls: ['./task-history-dialog.component.scss']
})
export class TaskHistoryDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<TaskHistoryDialogComponent>, @Inject(MAT_DIALOG_DATA) public taskItem : vwTaskListItem,
    private translate : TranslateService
  ) { 

  }

  sumTime: number = 0 ;
  ngOnInit() {
    // 
    // const timeLine  = this.taskItem.TimeLine;
    // const startTimeIndex = timeLine.findIndex( item=>item.TaskToState == TaskStates.Running);

    // for( var  i = startTimeIndex; i< timeLine.length;i++  ) {

    // }
  }

  translatedTaskState(state: string) {
    switch (state) {
      case TaskStates.Accepted:
        return this.translate.instant("common.task_inspected");
      case TaskStates.Created:
        return this.translate.instant("common.task_states_created");
      case TaskStates.Deleted:
        return this.translate.instant("common.task_deleted");
      // case TaskStates.InProgress:
      //   return this.translations["common.task_started"];
      case TaskStates.Paused:
        return this.translate.instant("common.task_pause");
      case TaskStates.Running:
        return this.translate.instant("common.task_started");
      case TaskStates.Ended:
        return this.translate.instant("common.task_ended");
      case "Modified":
        return this.translate.instant("common.modified");

    }
  }

}
