// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-dialog-container {
  padding: 20px !important
}


.min-width-300 {
  min-width: 300px;
}


.action-buttons {
  width: 100%;
  float: right !important;
  text-align: right !important;
  margin-top: 0.5em;
  padding-bottom: 15px !important;
}


.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white !important;
}

.mat-mdc-form-field-focus-overlay {
  background-color: white !important;
}


.hw-popup-title {
  font-family: "Roboto" !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  margin-bottom: 0em !important;
  color: black;
}

.mdc-button {
  min-width: 150px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
