// Angular 
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';

// HW 
import { environment } from "../../../environments/environment";
import { vwTransCleaning } from '../dto/CleaningTypeRoomState';
import { RequestResult } from '@app/core/dto/RequestResult';
import { SelectItem } from '@app/core/classes/SelectItem';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { HotelMode } from '../enums/HotelMode';
import { firstValueFrom } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CleaningtypeRoomstateService {

  apiServer = environment.apiHost + environment.apiPort;

  constructor(
    public http: HttpClient,
    private sessionStoregeService : SessionStorageService

  ) { }

  loadAll(Subscriber: string, hotelId: number) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.get(this.apiServer + '/api/kip/CleaningTypeRoomState/LoadAll/' + Subscriber + "/" + hotelId );
  }
  Save(data: vwTransCleaning) {

    //data,{headers}
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.put(this.apiServer + '/api/kip/CleaningTypeRoomState/SaveAll/' + data.Subscriber + "/" + data.Hotelid,data,{headers});
  }

  async setHotelMode(Subscriber: string, hotelId: number) {
  const result = await  firstValueFrom(this.http.get(this.apiServer + '/api/kip/CleaningTypeRoomState/LoadAll/' + Subscriber + "/" + hotelId ))
      var res = result as RequestResult;
      if (res) {
        const cleaningTypes = res.Data as vwTransCleaning;
        if( cleaningTypes.DayUse =='' && cleaningTypes.DepToArr =='' && cleaningTypes.DepToEmpty =='' &&  
            cleaningTypes.DepToOoo == '' && cleaningTypes.EmptyToArr == '' && cleaningTypes.EmptyToOoo == '' &&
            cleaningTypes.Occupied == '' && cleaningTypes.OccupiedBed == '' && cleaningTypes.OooToArr == '' && 
            cleaningTypes.OooToEmpty == '' && cleaningTypes.OooToOoo) {
              this.sessionStoregeService.HotelMode = HotelMode.ROOMSTATE_BASED
            } else {
              this.sessionStoregeService.HotelMode = HotelMode.TASK_BASED
            }
      }
  }
}


/*

        "DayUse": "AGYTAK",
        "DepToArr": "VALTAK",
        "DepToEmpty": "AGYTAK",
        
        "DepToOoo": "AGYTAK",
        "EmptyToArr": "ELLEN",
        "EmptyToOoo": "ELLEN",
        
        "Occupied": "NAPTAK",
        "OccupiedBed": "AGYTAK",
        "OooToArr": "ELLEN",
        
        "OooToEmpty": "ELLEN",
        "OooToOoo": "ELLEN"
*/