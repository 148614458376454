import { Component, OnInit, OnDestroy, AfterViewChecked, ChangeDetectorRef, ViewChild, ApplicationRef } from '@angular/core';
import { MenuService } from './core/services/menu.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from './core/services/auth.service';
import { TopBarManagerService } from './core/services/topbar-manager.service';

import { timer, first, firstValueFrom, Subject, Subscription, takeUntil } from 'rxjs';


import { GlobalMessageService } from './shared/services/global-message.service';
import { SpinnerService } from './core/services/spinner.service';
import { SessionStorageService } from './core/services/session-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, FormControl, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { RequestResult } from './core/dto/RequestResult';
import { Alert, ToastPosition } from '../../../shared/hw-alert-component/src/lib/hw-alert.model';
import { HwAlertService } from '../../../shared/hw-alert-component/src/lib/hw-alert.service';
import { MatDrawer } from '@angular/material/sidenav';
import { SidebarService } from './core/services/sidebar.service';
import dayjs from 'dayjs';
import { VersionService } from './services/version.service';
import { AssetsService } from './services/assets.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { HttpErrorResponse } from '@angular/common/http';
import { LoginService } from './core/services/login.service';
import { PushSubscriptionService } from './services/push-subscription.service';

import { environment } from '@environments/environment';
import { MessageRegistration } from './interfaces/MessageRegistration';
import { PushService } from './services/push.service';
import { ServiceWorkerModule, SwUpdate } from '@angular/service-worker';
import { SubscriberParameterService } from './kip/services/subscriber-parameter.service';
import { SubscriberParameterEnum } from './core/enums/subscriber-parameter.enum';
import { SubscriberParameter } from './kip/dto/SubscriberParameter';
import { Console, error } from 'console';
import { UserInfo } from './core/dto/UserInfo';
import { Result } from 'postcss';
import { HwConfirmationDialogService } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.service';
import { HwConfirmationDialogData } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.component';
import { Platform } from '@angular/cdk/platform';
import { PwaService } from './core/services/pwa.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: []
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked {

  @ViewChild(MatDrawer, { static: false }) drawer: MatDrawer;

  workerRegistration: ServiceWorkerRegistration
  destroy$: Subject<boolean> = new Subject<boolean>();
  loggedIn: boolean;
  private mediaQuerySubscription$: Subscription;
  sideBarIsOpened: boolean;
  spinnerVisible: boolean;
  topBarVisible: boolean;
  private isMobile: boolean;
  private sideBarStyle = {};
  public passwordChangeDialogVisible: boolean;
  changePasswordForm: FormGroup;
  loaded: boolean = true;
  rolesLoaded: boolean
  loadLocaleVersion$: Subscription;
  loadRemoteVersion$: Subscription;
  localeVersion: string;
  pushEnabled: boolean;

  modalPwaEvent: any;
  modalPwaPlatform: string|undefined;

  constructor(
    private menuService: MenuService,
    private topBarManagerService: TopBarManagerService,
    public authService: AuthService,
    private router: Router,
    private messageService: HwAlertService,
    private spinnerService: SpinnerService,
    private sessionStorageService: SessionStorageService,
    private cd: ChangeDetectorRef,
    private sideBarService: SidebarService,
    private fb: FormBuilder,
    private loginService: LoginService,
    private pushService: PushService,
    private subsParams: SubscriberParameterService,
    private swUpdate: SwUpdate,
    private confirmService: HwConfirmationDialogService,
    private translate: TranslateService,
    private hwAlertService: HwAlertService,
    private platform: Platform,
    private pwaService: PwaService

  ) {


    const passwordErrorValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
      const password = control.get('newPassword');
      const repeatPassword = control.get('newPasswordAgain');
      return password.value != repeatPassword.value ? { 'passwordError': true } : null;
    };
    this.changePasswordForm = fb.group({
      'oldPassword': new FormControl('', [Validators.required, , Validators.maxLength(20)]),
      'newPassword': new FormControl('', [Validators.required, , Validators.maxLength(20)]),
      'newPasswordAgain': new FormControl('', [Validators.required, , Validators.maxLength(20)])
    }, { validators: passwordErrorValidator });

    dayjs.locale('hu')
    // console.log( dayjs().format('YYYY.MM.DD HH:mm:ss') +  '- app.component created' ) 


  }
  ngAfterViewChecked(): void {
    this.sideBarIsOpened = false;
    this.topBarVisible = false;
    this.cd.detectChanges();
  }

  //#region =============== Angular Lifecyle events =================================================
  async ngOnInit() {
    this.pwaService.addToHomeScreen.subscribe(event=> {
      this.addToHomeScreen();
    })
  
    this.swUpdate.versionUpdates.subscribe(evt => {

      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`Current app version: ${evt.currentVersion.hash}`);
          console.log(`New app version ready for use: ${evt.latestVersion.hash}`);

          const options: HwConfirmationDialogData = {
            cancelButtonCaption: this.translate.instant("common.no"),
            message: this.translate.instant("common.new_software_version_arrived"),
            title: this.translate.instant("common.message"),
            okButtonCaption: this.translate.instant("common.yes")
          }
          this.confirmService.open(options);
          this.confirmService.confirmed().subscribe(confirmed => {
            if (confirmed) {
              document.location.reload();
            }
          })
          break;
        case 'VERSION_INSTALLATION_FAILED':
          this.hwAlertService.error(this.translate.instant("common.new_software_version_error"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
          console.error(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          break;
      }
    })
    this.loadModalPwa();

    this.loginService.LoginProcessFinished.subscribe(async (userInf: UserInfo) => {
      if (this.sessionStorageService.getSelectedUnit()?.length > 0) {
        const result = await firstValueFrom(this.subsParams.loadByTypeCode(this.sessionStorageService.getSelectedUnit(), this.sessionStorageService.getSelectedHotelId(),
          SubscriberParameterEnum.SEND_NOTIFICATIONS))
        const res = result as SubscriberParameter;
        if (res.Param_Int_Value === 1) {
          this.pushEnabled = true;
          const vapid = environment.publicVapidKey;
          navigator.serviceWorker.register('/assets/sw.js?v=5', { scope: '/assets/' }).then((serviceWorkerRegistration) => {
            const options = {
              userVisibleOnly: true,
              applicationServerKey: vapid,
            };

            serviceWorkerRegistration.pushManager.subscribe(options).then(
              (pushSubscription) => {
                //console.log(pushSubscription.endpoint);
                let messageReg: MessageRegistration = {
                  Email: userInf.UserName,
                  UserName: userInf.UserName,
                  EndPoint: pushSubscription
                }
                this.sessionStorageService.MessagingToken = messageReg;
                this.pushService.SubscribeForPush(messageReg).subscribe({
                  next: result => {
                    //console.log('token ok:' +  result)
                  },
                  error: err => {
                    //console.error('cannot get token ' + err)
                  }
                })
              })
            serviceWorkerRegistration.onupdatefound = () => {
              serviceWorkerRegistration.update().then(res => {
                console.log("serviceworker updated")
              })
            }
          });
        }
      }
      setInterval(() => {
        if (this.pushEnabled) {
          this.checkTokenExpiration();
        }

      }, 5 * 60 * 1000);
    })

    this.topBarManagerService.topBarVisibilityChanged.subscribe(event => {
      this.topBarVisible = event;
    });

    this.topBarManagerService.LoggedIn.subscribe(result => {
      this.loggedIn = result;
    })

    this.spinnerService.spinnerVisibilityChanged.subscribe(event => {
      this.spinnerVisible = event;
    })

    this.authService.rolesLoaded.subscribe(xx => {
      this.rolesLoaded = true;
    })

  }
  ngOnDestroy(): void {
    this.mediaQuerySubscription$?.unsubscribe();
    this.loadLocaleVersion$?.unsubscribe();
    this.loadRemoteVersion$?.unsubscribe();
  }



  //#endregion ======================================================================================

  //#region =============== Functions ================================================================
  toggleMenu() {
    this.drawer.toggle();
    this.sideBarService.sidebarToggle.emit();
  }
  toggleSidebar() {
    this.sideBarIsOpened = !this.sideBarIsOpened;
  }

  loadModuleMenu(module: string) {

    this.menuService.loadModuleMenu(module);
  }

  getSideBarStyle() {
    return {};
  }

  getVisibility(visible: boolean) {
    let styles = {};
    if (this.topBarVisible) {
      styles['display'] = 'block'
    } else {
      styles['display'] = 'none'
    }
  }
  //==================================================================================================

  openChangePasswordDialog() {
    this.passwordChangeDialogVisible = true;
  }
  /* form events */
  onEditDialogOkClick(oldPassword, newPassword) {
    this.authService.changePassword(this.sessionStorageService.getUserInfo().UserName, oldPassword.value,
      newPassword.value).subscribe(event => {
        if (event) {
          const res: RequestResult = event as RequestResult;
          if (res.Code === -1) {
            this.messageService.error('Hibás régi jelszó !', new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));
          }
          else {
            this.messageService.success('Jelszó módosítás rendben', new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
            this.authService.logout();
          }
        }
      })
    this.passwordChangeDialogVisible = false;
  }
  onEditDialogCancelClick() {
    this.passwordChangeDialogVisible = false;
  }

  checkTokenExpiration() {
    this.pushService.CheckTokenExpiration(this.sessionStorageService.MessagingToken.EndPoint).subscribe(
      {
        next: (result) => {
          // érvényes a token mindenki boldog     
        },
        error: (err: HttpErrorResponse) => {
          this.createOrRefreshToken();
        }
      }
    )

  }

  createOrRefreshToken() {
    const vapid = environment.publicVapidKey;
    const options = {
      userVisibleOnly: true,
      applicationServerKey: vapid,
    };
    const userInf = this.sessionStorageService.getUserInfo() as UserInfo;
    navigator.serviceWorker.getRegistration().then(serviceWorkerRegistration => {
      serviceWorkerRegistration.pushManager.subscribe(options).then(
        (pushSubscription) => {
          //console.log(pushSubscription.endpoint);
          let messageReg: MessageRegistration = {
            Email: userInf.UserName,
            UserName: userInf.UserName,
            EndPoint: pushSubscription
          }
          this.sessionStorageService.MessagingToken = messageReg;
          this.pushService.SubscribeForPush(messageReg).subscribe({
            next: result => {
              //console.log('token ok:' +  result)
            },
            error: err => {
              //console.error('cannot get token ' + err)
            }
          })
        })
    })
  }


  // pwa  
  private loadModalPwa(): void {
    if (this.platform.ANDROID ) {
      window.addEventListener('beforeinstallprompt', (event: any) => {
        event.preventDefault();
        this.modalPwaEvent = event;
        this.modalPwaPlatform = 'ANDROID';
      });
    }
    if (this.platform.isBrowser ) {
      window.addEventListener('beforeinstallprompt', (event: any) => {
        event.preventDefault();
        this.modalPwaEvent = event;
        this.modalPwaPlatform = 'BROWSER';
      });
    }
  }

  public addToHomeScreen(): void {
    this.modalPwaEvent.prompt();
    this.modalPwaPlatform = undefined;
  }
}