// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.export-column {
  background-color: #03a9f4;
  height: 200px;
  color: white;
}

.wrapper {
  margin: -60px 0px 0px 0px;
  position: relative !important;
  padding: 2em;
  background-color: #fff;
}

.child-item {
  margin-left: 1.3em;
}

.mat-expansion-panel-header.mat-expanded {
  height: initial;
  min-height: 56px;
}

.mat-expansion-panel-body {
  padding: 0 4px 4px !important;
}

.mat-mdc-optgroup-label {
  font-weight: 600 !important;
  color: black !important;
  margin-left: -10px;
}

.item-header {
  padding: 0px 8px !important;
}

/* overrides */
.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.mat-button-toggle-checked {
  background-color: #3f51b5;
  color: white !important;
}

.filter-wrapper {
  background: white;
  width: 300px;
  float: none;
}

.xyz {
  position: absolute !important;
  min-height: 56px;
}

.working-area-separator {
  border-right: 2px solid rgb(207, 207, 207);
}

/* panel classes */
.user-real-name {
  font-size: 18px;
  font-weight: 500;
  padding-top: 0.1em;
}

.user-number-of-rooms {
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  padding-top: 0.3em;
}

.user-sum-time {
  font-size: 15px;
  font-weight: 500;
  padding-top: 0.3em;
}

.value-column {
  font-size: 10;
}

.free-card-tasks-row {
  height: 60vh;
  overflow-x: hidden;
}

.avatar-container {
  width: 40px !important;
  height: 40px !important;
  float: right;
}

.avatar-content {
  font: 15px/40px Helvetica, Arial, sans-serif !important;
}

.save-button {
  height: 40px;
  width: 40px;
}

.hw-horizontal-divider {
  display: flex;
  background: #dddddd;
  height: 1px;
  margin-right: 10px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.hw-container-background {
  background-color: #f5f5f5;
}

.scheduler-save-button {
  background-color: #41c300 !important;
  color: white !important;
  float: right;
}

.dummy-item {
  box-shadow: 0px 0px 1px -1px rgba(0, 0, 0, 0.1), 0px 0px 4px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  border: 1px dashed #03a9f4;
  color: #484848;
  height: 80px;
  text-align: center;
  background-color: #ffffff;
  line-height: 80px;
  font-weight: 500;
}

.mat-expansion-panel {
  overflow: inherit !important;
}

.filter-row {
  width: 100%;
}

@media only screen and (max-width: 996px) {
  .filter-row {
    width: auto;
  }
}
@media only screen and (max-width: 767px) {
  .free-card-tasks-row {
    height: auto;
  }
  .mat-expansion-panel-header {
    padding: 0 0px 0px 20px !important;
    margin-right: 20px !important;
  }
}
.card-clicking-area {
  width: 90% !important;
}

.mat-expansion-panel-header.mat-expanded:focus, .mat-expansion-panel-header.mat-expanded:hover {
  background: inherit;
  background-color: white !important;
}

.mat-mdc-raised-button {
  min-width: 65px;
  width: 65px;
}

.mat-mdc-raised-button:hover {
  min-width: 65px;
  width: 65px;
}

.mat-mdc-raised-button:not(:disabled) {
  min-width: 65px;
  width: 65px;
}

@media only screen and (max-width: 767px) {
  .mat-mdc-raised-button {
    min-width: 55px;
    width: 55px;
  }
  .mat-mdc-raised-button:hover {
    min-width: 55px;
    width: 55px;
  }
  .mat-mdc-raised-button:not(:disabled) {
    min-width: 55px;
    width: 55px;
  }
  .mat-mdc-unelevated-button > .mat-icon, .mat-mdc-raised-button > .mat-icon, .mat-mdc-outlined-button > .mat-icon {
    font-size: 20px !important;
    height: 20px !important;
    width: 20px !important;
  }
}
.mat-mdc-unelevated-button > .mat-icon, .mat-mdc-raised-button > .mat-icon, .mat-mdc-outlined-button > .mat-icon {
  font-size: 24px !important;
  height: 24px !important;
  width: 24px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.group-label {
  margin-top: -0.5em !important;
  margin-left: -1px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
